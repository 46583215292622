import React, { Component } from 'react';
import { Form, Row, Col, Card, Button, Modal, Tabs, Tab, Dropdown } from 'react-bootstrap';
import { Redirect } from "react-router-dom";
import DatePicker from "react-datepicker";
import { Fetcher } from "../../../Helpers/fetcher.js";
import { Notify } from "../../../Helpers/notification.js";
import { cloneDeep, isEmpty, values } from 'lodash';
import "react-datepicker/dist/react-datepicker.css";
import "./index.css";

class AddVoiceCampaign extends Component {
	constructor(props) {
		super(props);

		this.baseFileInput = React.createRef();
		let sitePath = '';
		let filePath = '';

		const protocol = window.location.protocol;
		const slashes = protocol.concat("//");
		sitePath = slashes.concat(window.location.hostname);

		this.state = {
			campaignName: '',
			baseName: '',
			patchList: '',
			templateId: '0',
			dtmf: '',
			agentNumber: '',
			agentDtmf: '',
			baseFileType: 'contactList',
			sendLater: false,
			sendDate: new Date(),
			baseFileInput: this.baseFileInput,
			userId: props.app.userId,
			apiPath: props.app.apiPath,
			sitePath: sitePath,
			filePath: filePath,
			contactList: '',
			oldBaseId: '',
			promptId: '',
			welcomeId: '',
			menuId: '',
			noinputId: '',
			wronginputId: '',
			thanksId: '',
			senderId: '',
			baseUploads: [],
			promptUploads: [],
			senderIdList: [],
			redirectToSummary: false,
			campaignCreated: false,
			sendSmsSuccess: false,
			sendSmsFail: false,
			sendSmsPatchSuccess: false,
			sendSmsPatchFail: false,
			sendSmsDtmf: false,
			prompt: true,
			welcomePrompt: false,
			menuPrompt: true,
			noinputPrompt: false,
			wronginputPrompt: false,
			thanksPrompt: false,
			sendLink: false,
			smsLink: '',
			smsApiSuccess: '',
			smsSuccessMessage: '',
			smsApiFail: '',
			smsFailMessage: '',
			smsApiDtmf: '',
			smsDtmfMessage: '',
			callDurationSMS: 0,
			trunk: '',
			agentRows: [],
			menuWaitTime: '',
			rePrompt: '',
			ivrList: [],
			retries: 0,
			retryInterval: 0,
			moduleList: [],
			customivrFlag: false,
			ttsFlag: false,
			retryFlag: false,
			locationList: [],
			location: [],
			loading: false,
			selectAllLocations: false,
			allocatedDedicatedCliList: [],
			selectedCLI: [],
			selectAllCLI: false,
			DedicatedCliFlag: false,
			webhookFlag : false,
			webhookList  : [],
			webhookId : '',
			sendWebhook : false,
			selectedType : '',
			selectedPromptId : '',
			selectedValue : '',
			valueList : [1,2,3,4,5,6,7,8,9,10],
			ttsRows : [],
			ttsList : [],
			showTTSTable : false,
			gender : '',
			language : '',
			variableType : '',
			seqArray : [],
			groupAgentList : [],
			groupId : '',
			groupName : '',
			noAgentPrompt: false,
			noAgentId: '',
			requiredValue : true,
			ttsTypeListApi : [],
			ttsTemplateType : '',
			locationNames : "",
			callPatchSuccessMessage : '',
			callPatchFailMessage : ''
		}

	}

	componentDidMount() {
		const userId = this.state.userId;
		const apiPath = this.state.apiPath;

		// Get BaseUpload Files List
		Fetcher.get(apiPath + '/api/obd/base/' + userId)
			.then(res => {
				this.setState({
					baseUploads: res
				})
			})
			.catch(err => { console.log('Error in fetching BaseUpload Files', err) });

		// Get PromptUpload Files List
		Fetcher.get(apiPath + '/api/obd/prompts/' + userId)
			.then(res => {
				this.setState({
					promptUploads: res
				})
			})
			.catch(err => { console.log('Error in fetching PromptUpload Files', err) });
		// Get User Location list
		Fetcher.get(apiPath + '/api/obd/user/locations/' + userId)
			.then(res => {
				this.setState({
					locationList: res
				})
			})
			.catch(err => { console.log('Error in fetching Location List', err) });

		// Get IVR List
		Fetcher.get(apiPath + '/api/obd/ivr/' + userId)
			.then(res => {
				this.setState({
					ivrList: res
				})
			})
			.catch(err => { console.log('Error in fetching IVR List', err) });

		// Get Sender Id List
		Fetcher.get(apiPath + '/api/obd/cli/user/' + userId)
			.then(res => {
				this.setState({
					senderIdList: res
				})
			})
			.catch(err => { console.log('Error in fetching Sender Id\'s', err) });
		
		// Get User Module List
		Fetcher.get(apiPath + '/api/obd/module/list/' + userId)
			.then(res => {
				this.setState({
					moduleList: res
				})
				if (!isEmpty(this.state.moduleList) && (!('error' in this.state.moduleList))) {
					this.state.moduleList.map((fileObj) => {
						if (fileObj.moduleName === 'Auto Retry Campaign') {
							this.state.retryFlag = true;
						}
						else if (fileObj.moduleName === 'Custom IVR') {
							this.state.customivrFlag = true;
						}
						else if (fileObj.moduleName === 'Dedicated Cli') {
							this.state.DedicatedCliFlag = true;
							this.allocateDedicatedCli(userId, apiPath);
						}
						else if(fileObj.moduleName === 'Webhooks'){
                            this.state.webhookFlag = true;
							console.log("Webhook Flag :"+this.state.webhookFlag);
						}
						else if(fileObj.moduleName === 'TTS'){
                            this.state.ttsFlag = true;
							console.log("TTS Flag :"+this.state.ttsFlag);
						}
					})
				}
			})
			.catch(err => { console.log('Error in fetching Modules', err) });


			// Get Group Agent List
			Fetcher.get(apiPath + '/api/obd/group/agent/list/' + userId)
			.then(res => {
				this.setState({
					groupAgentList : res
				})
			})
			.catch(err => { console.log('Error in fetching Group Agent\'s', err) });





            Fetcher.get(apiPath + '/api/obd/webhooks/' + userId)
			.then(res => {
				this.setState({
					webhookList: res
				})
				console.log("WebhookList : "+JSON.stringify(this.state.webhookList));
				
			})
			.catch(err => { console.log('Error in fetching Modules', err) });

			Fetcher.get(apiPath + '/api/obd/tts/type/list')
			.then(res => {
				this.setState({
					ttsTypeListApi: res
				})
				console.log("TTS Type List : "+JSON.stringify(this.state.webhookList));
				
			})
			.catch(err => { console.log('Error in fetching TTS Type List', err) });

	}

	allocateDedicatedCli = (userId, apiPath) => {
		Fetcher.get(apiPath + '/api/obd/dedicated/cli/allocated/' + userId)
			.then(res => {
				this.setState({
					allocatedDedicatedCliList: res
				})
			})
			.catch(err => {
				console.log('Error in fetching dedicated cli', err);
			});
	}


	sendLaterDateChange = date => {
		this.setState({
			sendDate: date
		});
	};
	toggleChange = (e, name) => {
		this.setState({
			[name]: !this.state[name],
		});
	}

	handleSelect = function (selectedItems) {
		const dtmfSelected = [];
		for (let i = 0; i < selectedItems.length; i++) {
			dtmfSelected.push(selectedItems[i].value);
		}
		this.setState({
			dtmf: dtmfSelected.toString()
		});
	}

	handleChange = idx => e => {
		const { name, value } = e.target;
		const agentRows = [...this.state.agentRows];
		agentRows[idx] = {
			[name]: value
		};
		this.setState({
			agentRows
		});
	};
     

    getGrouName(gId){
        
		console.log("Group Id : "+gId);
		for(let i=0;i<this.state.groupAgentList.length;i++){
			if(this.state.groupAgentList[i]["groupId"] == gId){
				this.state.groupName = this.state.groupAgentList[i]["groupName"];
				break;
			}
			continue;
		}
		console.log("Group name : "+this.state.groupName);
	}



	handleClickPlus = () => {

        
		if(this.state.selectedType!=='' && this.state.selectedType==='prompt'){
                 console.log("Inside Prompt");
				 if(this.state.selectedPromptId!==''){

					const item = {
				        ttsType: this.state.selectedType,
				        value : this.state.selectedPromptId,
						variableType : ''
			        };

					const checkPrompt = obj => obj.value === this.state.selectedPromptId;
			        // if(this.state.ttsRows.some(checkPrompt))
			        // {
					//     Notify.notify('Prompt '+this.state.selectedPromptId+ 'is already selected');
					//     return;
			        // }
			        this.state.ttsRows.push(item);
			        this.setState({
				       ttsRows: this.state.ttsRows
			        });
			       this.state.ttsList = {
				      "ttsList": this.state.ttsRows
			       }

				   

				 }else{
					Notify.notify("Please Select Prompt");
				 }
		}
		else if(this.state.selectedType!=='' && this.state.selectedType==='variable'){
                  console.log("Inside Variable");

				  if(this.state.valueList.length==0){

					return;
				  }
                   
                console.log(">>>>>...."+this.state.valueList);
				if(this.state.valueList.length>1){
					if(this.state.valueList.length==10){
						console.log(".........."+this.state.selectedValue);
						if(this.state.selectedValue>1){
							console.log("comes herr 1")
							Notify.notify("Please Select Lower Variable Value First.");
                           return;
						}
					}
					else if(Number(this.state.valueList[0]) != Number(this.state.selectedValue)){
							console.log("comes herr 2");
						Notify.notify("Please Select Lower Variable Value First..");
                        return;
					}
				}
   
				  if(this.state.selectedValue !==''){

					const item = {
				        ttsType: this.state.selectedType,
				        value : this.state.selectedValue,
						variableType : this.state.variableType
			        };

					
			        this.state.ttsRows.push(item);
			        this.setState({
				       ttsRows: this.state.ttsRows
			        });
			       this.state.ttsList = {
				      "ttsList": this.state.ttsRows
			       }

				   let newArr = this.state.valueList.filter(element => element != this.state.selectedValue);
                   console.log(">>>>>>> "+newArr);
				   this.setState({
					    "valueList" : newArr,
                        selectedValue : Number(this.state.selectedValue)+1
				   })

				//    this.setState(prevState => ({
                //     seqArray: [...prevState.seqArray, this.state.selectedValue] // Adds newValue to the array
                //    }));

				 console.log("Selee "+this.state.seqArray);
                 
				 if(this.state.valueList.length===1){
					  console.log("htttttttttttttttttttttttttt");
                         this.setState({
							requiredValue : false
						 })
				}

				  }else{
					Notify.notify("Please Select Variable");
				  }
		}
		else {
			Notify.notify("Please Select TTS Action");
		}
		
	}

	handleAddRow = () => {
		if (this.state.groupId !== '' && this.state.agentDtmf !== '') {

			this.getGrouName(this.state.groupId);
			
			const item = {
				groupId: this.state.groupId,
				agentDtmf: this.state.agentDtmf,
				groupName : this.state.groupName
			};
			const checkDtmf = obj => obj.agentDtmf === this.state.agentDtmf;
			if(this.state.agentRows.some(checkDtmf))
			{
					Notify.notify(this.state.agentDtmf+ ' DTMF is already selected');
					return;
			}
			this.state.agentRows.push(item);
			this.setState({
				agentRows: this.state.agentRows
			});
			this.state.patchList = {
				"patchList": this.state.agentRows
			}
		}
		else {
			Notify.notify("Please select Agent Group and DTMF");
		}
	};
	handleRemoveRow = () => {
		this.setState({
			agentRows: this.state.agentRows.slice(0, -1)
		});
	};

	handleRemoveTTSRow = (index) => {

		console.log("INDEX >>>>>>>>>>>>>> "+index);
		let arr1 = this.state.ttsRows;
		let arr2= this.state.ttsRows.splice(index,1);
		let arr3 = [];
		 for(let i=0;i<arr1.length;i++){
			// if((arr1[0].ttsType === arr2[0].ttsType) && (arr1[0].value === arr2[0].value)){
			// 	continue;
			// }else{
            //     arr3.push(arr1[i]);
			// }
			arr3.push(arr1[i]);
		 }
		 this.setState({
		  ttsRows: arr3
		 });
		 let ttsval = arr2[0].value;
		 this.state.valueList.push(ttsval);
		 this.state.valueList.sort((a, b) => a - b);
		 this.setState({
               selectedValue : this.state.valueList[0]
		 });
	};

	setvalue = (e) => {
		const { name, value } = e.target;
		if (name === "campaignName") {
			const regex = /^[a-zA-Z0-9_-]*$/; // to match only digits, alphabets, minus, and underscore
	
			if (regex.test(value)) {
				this.setState({
					[name]: value,
				});
			}
		} else {
		    this.setState({
			    [e.target.name]: e.target.value,
		    });
		}
		
		if(name === "ttsTemplateType"){
			if(value === "7_2"){
				this.setState({
					templateId : "8"
				})
			}else if(value === '7_3'){
				this.setState({
					templateId : "9"
				})
			}
		}
	}
	filterSelectedCliByLocation = () => {
		const { selectedCLI, location, allocatedDedicatedCliList } = this.state;
		const selectedLocationNames = location.map(location => this.getLocationNameById(location.locationId));
		const filteredCLIs = selectedCLI.filter(cli => {
			const cliLocationName = allocatedDedicatedCliList.find(item => item.cliId.toString() === cli.cliId)?.locationName;
	
			// Check if the location name of the CLI is among the selected location names
			return cliLocationName && selectedLocationNames.includes(cliLocationName);
		});
		this.setState({ selectedCLI: filteredCLIs, selectAllCLI: false });
	};
	
	handleLocationSelect = (locationId) => {
		const { location } = this.state;
		const updatedLocations = location.some(loc => loc.locationId === locationId)
		  ? location.filter(loc => loc.locationId !== locationId)
		  : [...location, { locationId, locationName: this.getLocationNameById(locationId) }];
		
		this.setState({ location: updatedLocations, selectAllLocations: false }, () => {
			// Callback after state is updated
			this.filterSelectedCliByLocation();
		});
		let totalLocation = "";
		for(let i=0;i<updatedLocations.length;i++){
			totalLocation = totalLocation + updatedLocations[i].locationName + ",";
		}
		console.log("gggggggggggg : "+totalLocation);
		this.setState({
			locationNames : totalLocation.slice(0,totalLocation.length-1)
		})
	}
	handleCLICheckboxChange = (cliId) => {
		const { selectedCLI } = this.state;
		const cliIdStr = cliId.toString();
		const isSelected = selectedCLI.some(cli => cli.cliId === cliIdStr);
		const updatedCLI = isSelected
		  ? selectedCLI.filter(cli => cli.cliId !== cliIdStr)
		  : [...selectedCLI, { cliId: cliIdStr, cli: this.getCliById(cliId) }];
		this.setState({ selectedCLI: updatedCLI, selectAllCLI: false });
	  };	  
	handleSelectAllLocations = () => {
		//console.log("GGGGGGGGGGGGGGGGGG");
		const { selectAllLocations, locationList } = this.state;
		console.log("GGGGGGGGGGGGGGGGGG" + selectAllLocations);
		if (!selectAllLocations) {
		  const allLocations = locationList.map(loc => ({
			locationId: loc.locationId,
			locationName: loc.locationName
		  }));
		  this.setState({ location: allLocations, selectAllLocations: true }, () => {
			// Callback after state is updated
			this.filterSelectedCliByLocation();
			});

			let totalLocation = "";
			for(let i=0;i<allLocations.length;i++){
				 totalLocation = totalLocation + allLocations[i].locationName+",";
			}
			console.log(":::::::"+totalLocation);
			this.setState({
				locationNames : totalLocation.slice(0,totalLocation.length-1)
			})
		} else {
		  this.setState({ location: [], selectAllLocations: false }, () => {
			// Callback after state is updated
			this.filterSelectedCliByLocation();
		});
		console.log("OOOOOOOOOOO : "+JSON.stringify(selectAllLocations));
		}
	  }
	handleSelectAllCLI = () => {
	const { selectAllCLI, allocatedDedicatedCliList } = this.state;
	if (!selectAllCLI) {
		const allCLI = allocatedDedicatedCliList.map(cli => ({
			cliId: cli.cliId.toString(),
			cli: cli.cli.toString()
		  }));
		this.setState({ selectedCLI: allCLI, selectAllCLI: true });
	} else {
		this.setState({ selectedCLI: [], selectAllCLI: false });
	}
	};
	getLocationNameById = (locationId) => {
		const location = this.state.locationList.find(loc => loc.locationId === locationId);
		return location ? location.locationName : '';
	}
	getCliById = (cliId) => {
		const cli = this.state.allocatedDedicatedCliList.find(cli => cli.cliId === cliId);
		return cli ? cli.cli.toString() : '';
	}
	setTemplate = (e) => {
		this.setState({
			//campaignName: '',
			baseName: '',
			patchList: '',
			dtmf: '',
			agentNumber: '',
			agentDtmf: '',
			baseFileType: 'contactList',
			sendLater: false,
			sendDate: new Date(),
			baseFileInput: this.baseFileInput,
			contactList: '',
			oldBaseId: '',
			promptId: '',
			welcomeId: '',
			menuId: '',
			noinputId: '',
			wronginputId: '',
			thanksId: '',
			senderId: '',
			redirectToSummary: false,
			campaignCreated: false,
			sendSmsSuccess: false,
			sendSmsFail: false,
			sendSmsDtmf: false,
			prompt: true,
			sendLink: false,
			smsLink: '',
			smsApiSuccess: '',
			smsSuccessMessage: '',
			smsApiFail: '',
			smsFailMessage: '',
			smsApiDtmf: '',
			smsDtmfMessage: '',
			callDurationSMS: 0,
			trunk: '',
			agentRows: [],
			templateId: e.target.value,
			welcomePrompt: false,
			menuPrompt: false,
			noinputPrompt: false,
			wronginputPrompt: false,
			thanksPrompt: false,
			menuWaitTime: '',
			rePrompt: '',
			retries: 0,
			retryInterval: 0
		});
	}
	setUploadType = (type, uploadTypeKey) => {
		this.setState({
			[uploadTypeKey]: type,
		});
	}

	onFileChangeHandler = event => {
		//if ( this.checkMimeType( event ) && this.checkFileSize( event ) ) {
		if (this.checkMimeType(event)) {

		}
	}

	// Add Campaign
	handleAddCampaignSubmit = async (event) => {
		event.preventDefault();
		this.setState({ loading: true });
		const formData = cloneDeep(this.state);
		console.log(formData);
		if (formData.agentRows.length === 0 && formData.templateId === '2') {
			this.setState({ loading: false });
			Notify.notify('Please add Agent and DTMF Row');
			return;
		}
		if (formData.agentRows.length === 0 && formData.templateId === '9') {
			this.setState({ loading: false });
			Notify.notify('Please add Agent and DTMF Row');
			return;
		}
		if (formData.ttsRows.length === 0 && formData.templateId === '7') {
			this.setState({ loading: false });
			Notify.notify('Please add TTS Rows');
			return;
		}
        if (formData.gender === '' && formData.templateId === '7') {
			this.setState({ loading: false });
			Notify.notify('Please Select Gender');
			return;
		}

		if (formData.language == '' && formData.templateId === '7') {
			this.setState({ loading: false });
			Notify.notify('Please Select Language');
			return;
		}

		if(formData.retries === 0 && formData.retryInterval > 0)
		{
			this.setState({ loading: false });
			Notify.notify('Please select Retry Attempts');
			return;
		}
		if(formData.retries > 0 && formData.retryInterval == 0)
		{
			this.setState({ loading: false });
			Notify.notify('Please select retry interval');
			return;
		}
		if (this.state.location.length === 0) {
			this.setState({ loading: false });
			Notify.notify('Please select a location');
			return;
		}
		// if (this.state.DedicatedCliFlag && this.state.selectedCLI.length === 0) {
		// 	this.setState({ loading: false });
		// 	Notify.notify('Please select a CLI');
		// 	return;
		// }
		const date = formData.sendDate;
		let sendDateTime = this.addZero(date.getFullYear());
		sendDateTime += "-";
		sendDateTime += this.addZero((date.getMonth() + 1));
		sendDateTime += "-"
		sendDateTime += this.addZero(date.getDate());
		sendDateTime += " "
		sendDateTime += this.addZero(date.getHours());
		sendDateTime += ":"
		sendDateTime += this.addZero(date.getMinutes());
		sendDateTime += ":"
		sendDateTime += this.addZero(date.getSeconds());
		var baseId = '';
		await this.uploadBaseAndPromptFiles(formData)
			.then(([resp]) => {
				if(formData.baseFileType === 'newBaseFile')
				{
					if(resp.baseId === undefined)
					{
						this.setState({ loading: false });
						Notify.notify(resp.message);
						return;
					}
					else
					{
						baseId = resp.baseId;
					}
				}
				else if (formData.baseFileType === 'oldBaseFile') {
					baseId = resp;
				}
				else if(formData.baseFileType === 'contactList')
				{
					baseId = resp.baseId;
				}
				if (this.state.patchList === '' && formData.templateId === 2) {
					this.setState({ loading: false });
					Notify.notify("Please add Agent Row");
					return;
				}
				if(!this.state.sendSmsSuccess)
				{
					formData.smsApiSuccess='';
					formData.callDurationSMS=0;
				}
				if(!this.state.sendSmsFail)
				{
					formData.smsApiFail='';
				}
				if(!this.state.sendSmsPatchSuccess)
				{
					formData.callPatchSuccessMessage='';
				}
				if(!this.state.sendSmsPatchFail)
				{
					formData.callPatchFailMessage='';
				}
				if(!this.state.sendSmsDtmf)
				{
					formData.smsApiDtmf='';
				}

                let wId = "";
				if(this.state.sendWebhook){
					if(this.state.webhookId !==""){
						wId = this.state.webhookId;
					}else{
						wId = ""
					}
				}

                if(this.state.templateId==="7"){
                    
					console.log("hhhheeeee......"+this.state.ttsTemplateType);
					if(this.state.ttsTemplateType ==="7_2"){
						console.log("hhhheeeee");
						this.setState({
							templateId : "8"
						});
					}else if(this.state.ttsTemplateType ==="7_3"){
						this.setState({
							templateId : "9"
						});
					}
				}

				const data = {
					"userId": formData.userId,
					"campaignName": formData.campaignName,
					"templateId": this.state.templateId,
					"dtmf": formData.dtmf,
					"baseId": baseId,
					"welcomePId": formData.welcomeId,
					"menuPId": formData.menuId,
					"noInputPId": formData.noinputId,
					"wrongInputPId": formData.wronginputId,
					"thanksPId": formData.thanksId,
					"scheduleTime": sendDateTime,
					"smsSuccessApi": formData.smsApiSuccess,
					"smsFailApi": formData.smsApiFail,
					"smsDtmfApi": formData.smsApiDtmf,
					"callDurationSMS": formData.callDurationSMS,
					"retries": formData.retries,
					"retryInterval": formData.retryInterval,
					"agentRows": JSON.stringify(this.state.patchList),
					"menuWaitTime" : formData.menuWaitTime,
					"rePrompt": formData.rePrompt,
					"ivrId": formData.ivrId,
					"location": JSON.stringify({ locationList: formData.location }),
					"clis":  this.state.selectedCLI.length ===  0 ? "" : JSON.stringify({ cliList: formData.selectedCLI }),
					"webhook": formData.sendWebhook,
					"webhookId": wId,
					"ttsRows": JSON.stringify(this.state.ttsList),
					"gender" : formData.gender,
					"language" : formData.language,
					"noAgentId": formData.noAgentId,
					"callPatchSuccessMessage": formData.callPatchSuccessMessage,
					"callPatchFailMessage": formData.callPatchFailMessage,
				}
				this.addNewCampiagn(data);
			})
	}

	uploadBaseAndPromptFiles = async (formData) => {
		return new Promise(async (resolve, reject) => {

			var baseId = await this.maybeUploadBasefile(formData);
			resolve([baseId]);
			//reject();
		})
		//return Promise.all( [ this.maybeUploadBasefile( formData ), this.maybeUploadWelcomefile( formData ), this.maybeUploadMenufile( formData ), this.maybeUploadNoinputfile( formData ), this.maybeUploadWronginputfile( formData ), this.maybeUploadThanksfile( formData ) ] )
	}

	maybeUploadBasefile = async (formData) => {
		if (formData.baseFileType === 'oldBaseFile') {
			return formData.oldBaseId
		}
		else if (formData.baseFileType === 'contactList') {
			const fd = new FormData();
			fd.append('userId', formData.userId);
			fd.append('contactList', formData.contactList);
			//console.log(fd);
			return Fetcher.post(formData.apiPath + '/api/obd/contactlist', { method: 'POST', body: fd })
				.then(res => {
					return res;
				})
				.catch(err => { 
					this.setState({ loading: false });
					console.log('Error in uploading BaseUpload Files to Server', err) 
				});
		}
		else {
			const fd = new FormData();
			fd.append('baseFile', formData.baseFileInput.current.files[0]);
			fd.append('baseName', formData.baseName);
			fd.append('userId', formData.userId);
			fd.append('contactList', '');
			return Fetcher.post(formData.apiPath + '/api/obd/baseupload', { method: 'POST', body: fd })
				.then(res => {
					return res;
				})
				.catch(err => { 
					this.setState({ loading: false });
					console.log('Error in uploading BaseUpload Files to Server', err) 
				});
		}
	}

	addNewCampiagn = (data) => {
		console.log(data);
		// this.state.apiPath
		// this.state.apiPath
		return Fetcher.post(this.state.apiPath + '/api/obd/campaign/compose', { headers: { "Content-type": "application/json" }, method: 'POST', body: JSON.stringify(data) })
			.then(res => {
				let campaignCreated = false;
				if (res.message === 'Parameters Incorrect') {
					Notify.notify(res.message);
				} else {
					campaignCreated = true;
					Notify.notify(res.message);
				}
				this.setState({ loading: false });
				this.setState({
					redirectToSummary: campaignCreated
				})
			})
			.catch(err => {
				this.setState({ loading: false });
				console.log('Error in Add Campaign', err);
				Notify.notify('Error in Add Campaign');
			});
	}


	addZero = (i) => {
		if (i < 10) {
			i = "0" + i;
		}
		return i;
	}

	getFileNameFromUrl = (url) => {
		var n = url.lastIndexOf('/');
		return url.substring(n + 1);
	}

	checkMimeType = (event) => {
		let files = event.target.files
		let allowedTypes = ['text/plain', 'text/csv'];
		if (event.target.name === 'newContactsFile') {
			allowedTypes = ['text/plain', 'text/csv'];
		} else {
			allowedTypes = ['audio/wav'];
		}
		let err = [] // create empty array

		for (var x = 0; x < files.length; x++) {
			if (allowedTypes.every(type => files[x].type !== type)) {
				err[x] = files[x].type + ' is not a supported format\n';
				// assign message to array
			}
		};
		for (var z = 0; z < err.length; z++) { // loop create toast massage
			event.target.value = null;
		}
		return true;
	}

	checkFileSize = (event) => {
		let allowedSize = 1;
		if (event.target.name === 'newContactsFile') {
			allowedSize = 20;
		}

		let files = event.target.files
		let err = [];
		for (var x = 0; x < files.length; x++) {
			if (((files[x].size / 1024 / 1024).toFixed(2)) > allowedSize) {
				err[x] = files[x].size + ' is too large, please pick a smaller file\n';
			}
		};
		for (var z = 0; z < err.length; z++) {
			event.target.value = null;
		}
		return true;
	}

	render() {
		// Redirect to Summary if Campaign Added successfully.
		if (this.state.redirectToSummary === true) {
			return <Redirect to='/voice-campaign-summary' />
		}

		const agentRows = cloneDeep(this.state.agentRows);
		const agentsTable = agentRows.map((item, index) => {
			return <tr key={index}>
				<td>{this.state.agentRows[index].groupName}</td>
				<td>{this.state.agentRows[index].agentDtmf}</td>
				<td>
					<i onClick={() => this.handleRemoveRow()} className="fa fa-minus-circle"></i>
				</td>
			</tr>;
		});


		const ttsRows = cloneDeep(this.state.ttsRows);
		const ttsTable = ttsRows.map((item, index) => {
			return <tr key={index}>
				<td>{this.state.ttsRows[index].ttsType}</td>
				<td>{this.state.ttsRows[index].value}</td>
				<td>{this.state.ttsRows[index].variableType}</td>
				<td>
					<i onClick={() => this.handleRemoveTTSRow(index)} className="fa fa-minus-circle"></i>
				</td>
			</tr>;
		});


		//console.log( 'Add Campaign State', this.state );
		const baseUploads = cloneDeep(this.state.baseUploads);
		const promptUploads = cloneDeep(this.state.promptUploads);
		const senderIdList = cloneDeep(this.state.senderIdList);
		const ivrList = cloneDeep(this.state.ivrList);
		const sendLater = this.state.sendLater;
		let sendLaterDatepicker = '';
		let submitButtonlabel = 'Send Now';

		if (sendLater) {
			const filterTime = (date) => {
				const isPastTime = new Date().getTime() > date.getTime();
				return !isPastTime;
				};
			sendLaterDatepicker = <Row>
				<Col sm={10}>
					<Form.Group className="form-group"  controlId="send-date">
						<Form.Label>Enter Date</Form.Label>
						<DatePicker
							className="form-control"
							showTimeSelect
							timeIntervals={15}
							minDate={new Date()}
							selected={this.state.sendDate}
							timeCaption="Time"
							dateFormat="yyyy-MM-dd HH:mm:ss"
							timeFormat='HH:mm'
							onChange={this.sendLaterDateChange}
							filterTime={filterTime}
						/>
					</Form.Group>
				</Col>
			</Row>

			submitButtonlabel = 'Compose Campaign';
		}

		let senderIdDropdown = '';
		if (!isEmpty(senderIdList) && (!('error' in senderIdList))) {
			senderIdDropdown = senderIdList.map((obj, index) => {
				return <option value={obj.cli} key={`senderid${index}`} >{obj.cli}</option>
			})

		} else {
			senderIdDropdown = <option value="" >No ID found</option>
		}

		let baseUploadDropdown = '';

		if (!isEmpty(baseUploads) && (!('error' in baseUploads))) {
			baseUploadDropdown = baseUploads.map((fileObj, index) => {
				return <option value={fileObj.baseId} key={`basefile${index}`} >{fileObj.baseName}</option>
			})

		} else {
			baseUploadDropdown = <option value="" >No Files</option>
		}

		const locationList = cloneDeep(this.state.locationList);
		let locationDropdown = '';
		if (!isEmpty(locationList) && (!('error' in locationList))) {
			locationDropdown = Array.isArray(locationList) ? locationList.map((obj, index) => {
			  return (
				<Form.Check
				  key={`location${index}`}
				  type="checkbox"
				  label={obj.locationName}
				  id={`location-${obj.locationId}`}
				  value={obj.locationId}
				  checked={this.state.location.some(loc => loc.locationId === obj.locationId)}
				  onChange={() => this.handleLocationSelect(obj.locationId)}
				/>
			  );
			}) : [];
		  } else {
			locationDropdown = <p>No location found</p>;
		}

       const webhookList = cloneDeep(this.state.webhookList);
		let webhookDropdown = '';
		if (!isEmpty(webhookList) && (!('error' in webhookList))) {
			webhookDropdown = Array.isArray(webhookList) ? webhookList.map((obj, index) => {
				return <option value={obj.id} key={`id${index}`} >{obj.webhookName}</option>
			}) : []

		} else {
			webhookDropdown = <option value="" >No Webhook found</option>
		}
        
		console.log("Webhook Id : "+this.state.webhookId);



		let ivrDropdown = '';

		if (!isEmpty(ivrList) && (!('error' in ivrList))) {
			ivrDropdown = Array.isArray(ivrList)?ivrList.map((fileObj, index) => {
				return <option value={fileObj.ivrId} key={`basefile${index}`} >{fileObj.ivrName}</option>
			}):[]

		} else {
			ivrDropdown = <option value="" >No Files</option>
		}

		let welcomeDropdown = '';
		let menuDropdown = '';
		let noinputDropdown = '';
		let wronginputDropdown = '';
		let thanksDropdown = '';
		let ttsDropdown = '';
		let ttsValueDropDown = '';
		let noAgentDropdown ='';
		let ttsTypeDropdown = '';
		let welcomeMenuDropdown = '';


		const gAList = cloneDeep(this.state.groupAgentList);
		let groupAgentDropdown = '';
		if (!isEmpty(gAList) && (!('error' in gAList))) {
			groupAgentDropdown = Array.isArray(gAList) ? gAList.map((obj, index) => {
			  return (
				 <option value={obj.groupId} key={`groupAgent${index}`} >{obj.groupName}</option>
			  );
			}) : [];
		  } else {
			groupAgentDropdown = <p>No Group found</p>;
		}


		const valList = cloneDeep(this.state.valueList);
		if(!isEmpty(valList) && (!('error' in valList))){

			ttsValueDropDown = Array.isArray(valList) ? valList.map((fileObj, index) => {
				
				return <option value={fileObj} key={`ttsValue${index}`} >{fileObj}</option>
				
			}) : [];
			
		}

		const typeTTSList = cloneDeep(this.state.ttsTypeListApi);
		if(!isEmpty(typeTTSList) && (!('error' in typeTTSList))){

			ttsTypeDropdown = Array.isArray(typeTTSList) ? typeTTSList.map((fileObj, index) => {
				
				return <option value={fileObj.tts_type} key={`ttsType${index}`} >{fileObj.tts_type}</option>
				
			}) : [];
			
		}else{
			ttsTypeDropdown = <p>No TTS Type found</p>;
		}

		if (!isEmpty(promptUploads) && (!('error' in promptUploads))) {

			welcomeDropdown = promptUploads.map((fileObj, index) => {
				if (fileObj.promptCategory === 'welcome') {
					return <option value={fileObj.promptId} key={`promptfile${index}`} >{fileObj.fileName}</option>
				}
				else {
					welcomeDropdown = <option value="" >No Files</option>
				}
			})

			welcomeMenuDropdown = promptUploads.map((fileObj, index) => {
				if (fileObj.promptCategory === 'welcome' || fileObj.promptCategory === 'menu') {
					return <option value={fileObj.promptId} key={`promptfile${index}`} >{fileObj.fileName}</option>
				}
				else {
					welcomeMenuDropdown = <option value="" >No Files</option>
				}
			})

			menuDropdown = promptUploads.map((fileObj, index) => {
				if (fileObj.promptCategory === 'menu') {
					return <option value={fileObj.promptId} key={`promptfile${index}`} >{fileObj.fileName}</option>
				}
				else {
					menuDropdown = <option value="" >No Files</option>
				}
			})

			noinputDropdown = promptUploads.map((fileObj, index) => {
				if (fileObj.promptCategory === 'noinput') {
					return <option value={fileObj.promptId} key={`promptfile${index}`} >{fileObj.fileName}</option>
				}
				else {
					noinputDropdown = <option value="" >No Files</option>
				}
			})

			wronginputDropdown = promptUploads.map((fileObj, index) => {
				if (fileObj.promptCategory === 'wronginput') {
					return <option value={fileObj.promptId} key={`promptfile${index}`} >{fileObj.fileName}</option>
				}
				else {
					wronginputDropdown = <option value="" >No Files</option>
				}
			})

			thanksDropdown = promptUploads.map((fileObj, index) => {
				if (fileObj.promptCategory === 'thanks') {
					return <option value={fileObj.promptId} key={`promptfile${index}`} >{fileObj.fileName}</option>
				}
				else {
					thanksDropdown = <option value="" >No Files</option>
				}
			})

			ttsDropdown = promptUploads.map((fileObj, index) => {
				if (fileObj.promptCategory === 'tts') {
					return <option value={fileObj.promptId} key={`promptfile${index}`} >{fileObj.fileName}</option>
				}
				else {
					ttsDropdown = <option value="" >No Files</option>
				}
			})

			 noAgentDropdown = promptUploads.map((fileObj, index) => {
				if (fileObj.promptCategory === 'noagent') {
					return <option value={fileObj.promptId} key={`promptfile${index}`} >{fileObj.fileName}</option>
				}
				else {
					noAgentDropdown = <option value="" >No Files</option>
				}
			})
		}

		const allocatedDedicatedcliList = cloneDeep(this.state.allocatedDedicatedCliList);
		let allocatedDedicatedCliDropdown = '';
		if (!isEmpty(allocatedDedicatedcliList) && (!('error' in allocatedDedicatedcliList))) {
			// Get selected location names
			const selectedLocationIds = this.state.location.map(loc => loc.locationId);
			const selectedLocationNames = selectedLocationIds.map(id => this.getLocationNameById(id));

			// Filter allocatedDedicatedCliList based on selected location names
			const filteredCLIs = allocatedDedicatedcliList.filter(cli => selectedLocationNames.includes(cli.locationName));
			allocatedDedicatedCliDropdown = Array.isArray(filteredCLIs) ? filteredCLIs.map((obj, index) => {
			return (
			<Form.Check
				key={`cliId${index}`}
				type="checkbox"
				label={`${obj.cli} (${obj.dialerName})`}
				id={`cli-${obj.cliId}`}
				value={obj.cliId}
				checked={this.state.selectedCLI.some(cli => cli.cliId === obj.cliId.toString())}
				onChange={() => this.handleCLICheckboxChange(obj.cliId)}
			/>
			);
		}) : [];
		} else {
		allocatedDedicatedCliDropdown = <p>No Allocated CLI found</p>;
		}

		return (

			<Form className="add-campaign-form" onSubmit={(e) => this.handleAddCampaignSubmit(e)}>
				<Row>
					<Col sm={12}>
						<Card>
							<Card.Header>Compose Voice Campaign</Card.Header>
							<Card.Body>
								<Row>
									<Col sm={6}>
										<Form.Group className="form-group" >
											<Form.Label>IVR Template</Form.Label>
											<div>
												<Form.Check required id="templateId-0" custom inline checked={this.state.templateId === "0"} label="Simple IVR" name="templateId" onChange={e => this.setTemplate(e)} value="0" type="radio" />
												<Form.Check required id="templateId-1" custom inline checked={this.state.templateId === "1"} label="DTMF Input" name="templateId" onChange={e => this.setTemplate(e)} value="1" type="radio" />
												<Form.Check required id="templateId-2" custom inline checked={this.state.templateId === "2"} label="Call Patch" name="templateId" onChange={e => this.setTemplate(e)} value="2" type="radio" />
												{this.state.customivrFlag && <Form.Check required id="templateId-3" custom inline checked={this.state.templateId === "3"} label="Custom IVR" name="templateId" onChange={e => this.setTemplate(e)} value="3" type="radio" />}
											    {this.state.ttsFlag && <Form.Check required id="templateId-7" custom inline checked={(this.state.templateId === "7" || this.state.templateId === "8" || this.state.templateId === "9")} label="TTS" name="templateId" onChange={e => this.setTemplate(e)} value="7" type="radio" /> }
											</div>
										</Form.Group>
									</Col>
								</Row>

                               {((this.state.templateId === '7' || this.state.templateId === '8' || this.state.templateId === '9')) && 
							      <Row>

									<Col sm={4}>
										<Form.Group className="form-group required control-label">
											
											<Form.Select className="dd" required name="ttsTemplateType" onChange={e => this.setvalue(e)} as="select" disabled={this.state.inputDisabilty}>
												<option value="7_1">TTS Simple IVR</option>
												<option value="7_2">TTS DTMF</option>
												<option value="7_3">TTS Call Patch</option>
											</Form.Select>
										</Form.Group>
									</Col>

								  </Row>
							   }

								<Row>
									<Col sm={6}>
										<Form.Group className="form-group"  controlId="campaign-name">
											<Form.Control required name="campaignName" onChange={e => this.setvalue(e)} value={this.state.campaignName} type="text" placeholder="Campaign Name" />
										</Form.Group>
									</Col>
								   
								</Row>

								<Row>

									{(this.state.templateId === '7' || this.state.templateId === '8' || this.state.templateId === '9') &&
									 
									   <Col sm={4}>
										<Form.Group className="form-group required control-label">
											
											<Form.Select className="dd" required name="language" onChange={e => this.setvalue(e)} as="select" disabled={this.state.inputDisabilty}>
												<option value="">Select Language</option>
												<option value="en-IN">English</option>
												<option value="hi-IN">Hindi</option>
											</Form.Select>
										</Form.Group>
									</Col>

	                                }

									{(this.state.templateId === '7' || this.state.templateId === '8' || this.state.templateId === '9') &&
									    
									<Col sm={4}>
										<Form.Group className="form-group required control-label">
											
											<Form.Select className="dd" required name="gender" onChange={e => this.setvalue(e)} as="select" disabled={this.state.inputDisabilty}>
												<option value="">Select Gender</option>
												<option value="MALE">MALE</option>
												<option value="FEMALE">FEMALE</option>
											</Form.Select>
										</Form.Group>
									</Col>
	                                
									}

								</Row>
                                
								<Row>
									 {(this.state.templateId === '7' || this.state.templateId === '8' || this.state.templateId === '9') &&
									<Col sm={3}>
									    <Form.Group className="form-group"  controlId="sender-id">
												
												<Form.Select required name="selectedType" onChange={e => this.setvalue(e)} value={this.state.selectedType} as="select">
													<option value="">Select Action</option>
													<option value="prompt">Prompt</option>
													<option value="variable">Variable</option>
												</Form.Select>
										</Form.Group>
									</Col> }
                                   
								   {((this.state.templateId === '7') && this.state.selectedType==='prompt') &&
								   
								      <Col sm={3}>
										
										<Form.Select as="select" required name="selectedPromptId" onChange={e => this.setvalue(e)}>
											<option value="">Select Prompt</option>
											{welcomeDropdown}
										</Form.Select>
									  </Col>

	                                }

									{((this.state.templateId === '8' || this.state.templateId === '9') && this.state.selectedType==='prompt') &&
								   
								      <Col sm={3}>
										
										<Form.Select as="select" required name="selectedPromptId" onChange={e => this.setvalue(e)}>
											<option value="">Select Prompt</option>
											{welcomeMenuDropdown}
										</Form.Select>
									  </Col>

	                                }

									{((this.state.templateId === '7' || this.state.templateId === '8' || this.state.templateId === '9') && this.state.selectedType==='variable') &&
								   
								      <Col sm={3}>
										
										<Form.Select as="select" required={this.state.requiredValue} name="selectedValue" onChange={e => this.setvalue(e)}>
											<option value="">Select Variable</option>
											{ttsValueDropDown}
										</Form.Select>
									  </Col>

	                                }

									{((this.state.templateId === '7' || this.state.templateId === '8' || this.state.templateId === '9') && this.state.selectedType==='variable') &&
								          <Col sm={3}>
									        <Form.Group className="form-group"  controlId="sender-id">
												
												{/* <Form.Select required name="variableType" onChange={e => this.setvalue(e)} value={this.state.variableType} as="select">
													<option value="">Select Type</option>
													<option value="date">Date</option>
													<option value="number">Number</option>
													<option value="text">Text</option>
												</Form.Select> */}

												<Form.Select required name="variableType" onChange={e => this.setvalue(e)} value={this.state.variableType} as="select">
											        <option value="">Select Variable</option>
											     {ttsTypeDropdown}
										        </Form.Select>
										    </Form.Group>
								     	</Col>
									}

									{((this.state.templateId === '7' || this.state.templateId === '8' || this.state.templateId === '9') && this.state.selectedType!='' ) &&
								     	
									<Col sm={3}>
											<i onClick={this.handleClickPlus } className="fa fa-plus-circle plus-icon"></i>
									</Col>
	                               }


								</Row>

								{(this.state.templateId === '7' || this.state.templateId === '8' || this.state.templateId === '9') && <Row>
									<Col sm={6}>
										<div className="react-bootstrap-table">

											<table className="table table-striped table-bordered table-sm widgetlist-table">
												<thead>
													<tr>
														<th>Type</th>
														<th>Value</th>
														<th>Variable Type</th>
														<th>Action</th>
													</tr>
												</thead>
												<tbody>
													{ttsTable}
												</tbody>
											</table>
										</div>
									</Col>
								</Row>}


								<Row>
									<Col sm={6}>
									<Dropdown className="d-inline-block w-100">
										<Dropdown.Toggle block variant="light" id="dropdown-basic" className="w-100 text-left" style={{color: '#23282c', backgroundColor: '#d1dbe1', borderColor: '#cad4dc'}}>
										{ (this.state.location.length>0) ? this.state.locationNames :  "Select Location"}
										</Dropdown.Toggle>

										<Dropdown.Menu className="pl-2 w-100">
											<Form.Group className="form-group">
												{/* <Form.Label>Select Location</Form.Label> */}
												{this.state.locationList.length > 1 &&
												<Form.Check
													type="checkbox"
													label="Select All"
													id="select-all-locations"
													checked={this.state.selectAllLocations}
													onChange={this.handleSelectAllLocations}
												/>
												}
													{locationDropdown}
											</Form.Group>
										</Dropdown.Menu>
      								</Dropdown>
									</Col>
                                    {console.log("??????????? "+JSON.stringify(this.state.location))}
                                    {(this.state.location.length > 0 && this.state.DedicatedCliFlag) &&
								
									<Col sm={6}>
										<Dropdown className="d-inline-block w-100">
											<Dropdown.Toggle block variant="light" id="dropdown-basic" className="w-100 text-left" style={{color: '#23282c', backgroundColor: '#d1dbe1', borderColor: '#cad4dc'}}>
											Select CLI
											</Dropdown.Toggle>

											<Dropdown.Menu className="pl-2 w-100">
												<Form.Group className="form-group">
													{/* <Form.Label>Select Location</Form.Label> */}
													{this.state.allocatedDedicatedCliList.length > 1 &&
													<Form.Check
														type="checkbox"
														label="Select All"
														id="select-all-cli"
														checked={this.state.selectAllCLI}
														onChange={this.handleSelectAllCLI}
													/>
													}
														{allocatedDedicatedCliDropdown}
												</Form.Group>
											</Dropdown.Menu>
										</Dropdown>
									</Col>
								}

								</Row>
								

								{((this.state.templateId === '8')) &&
									<Row>
										<Col sm={6}>
											<Form.Group className="form-group"  controlId="sender-id">
												<Form.Label>Select DTMF</Form.Label>
												<Form.Select required name="dtmf" onChange={(e) => { this.handleSelect(e.target.selectedOptions) }} multiple as="select">
													<option value="">Select</option>
													<option value="0">0</option>
													<option value="1">1</option>
													<option value="2">2</option>
													<option value="3">3</option>
													<option value="4">4</option>
													<option value="5">5</option>
													<option value="6">6</option>
													<option value="7">7</option>
													<option value="8">8</option>
													<option value="9">9</option>
													{/* <option value="*">*</option> */}
												</Form.Select>
											</Form.Group>
										</Col>
									</Row>}
								
								{(this.state.templateId === '1') &&
									<Row>
										<Col sm={6}>
											<Form.Group className="form-group"  controlId="sender-id">
												<Form.Label>Select DTMF</Form.Label>
												<Form.Select required name="dtmf" onChange={(e) => { this.handleSelect(e.target.selectedOptions) }} multiple as="select">
													<option value="">Select</option>
													<option value="0">0</option>
													<option value="1">1</option>
													<option value="2">2</option>
													<option value="3">3</option>
													<option value="4">4</option>
													<option value="5">5</option>
													<option value="6">6</option>
													<option value="7">7</option>
													<option value="8">8</option>
													<option value="9">9</option>
													{/* <option value="*">*</option> */}
												</Form.Select>
											</Form.Group>
										</Col>
									</Row>}
									{(this.state.templateId === '3') && <Row>
									<Col sm={4}>
										<Form.Label>Select IVR</Form.Label>
										<Form.Group className="form-group" >
											<Form.Select as="select" required name="ivrId" onChange={e => this.setvalue(e)}>
												<option value="">Select IVR</option>
												{ivrDropdown}
											</Form.Select>
										</Form.Group>
									</Col>
									</Row>}
									{(this.state.templateId === '0') && <Row>
									<Col sm={12}>
										<Form.Label>Upload Contacts</Form.Label>
										<Tabs defaultActiveKey="contactList" className="" onSelect={k => this.setUploadType(k, 'baseFileType')}>
											<Tab eventKey="contactList" title="Contact Numbers(Only 50K numbers can be copied)">
												<Form.Group className="form-group"  >
													<Form.Control onChange={e => this.setvalue(e)} name="contactList" as="textarea" rows="3" maxLength="549999" />
												</Form.Group>
											</Tab>
											<Tab eventKey="newBaseFile" title="Upload Contacts File(.csv OR .txt format only)">
												<Form.Group className="form-group"  >
													<Form.Control name="newContactsFile" onChange={this.onFileChangeHandler} ref={this.baseFileInput} type="file" />
												</Form.Group>
												<Form.Group className="form-group" >
													<Form.Control name="baseName" onChange={e => this.setvalue(e)} value={this.state.baseName} type="text" placeholder="Base File Name" />
												</Form.Group>
											</Tab>
											<Tab eventKey="oldBaseFile" title="Existing Contacts">
												<Form.Group className="form-group"  >
													{/* <Form.Label>Select File</Form.Label> */}
													<Form.Select as="select" name="oldBaseId" onChange={e => this.setvalue(e)} >
														<option value="">Select File</option>
														{baseUploadDropdown}
													</Form.Select>
												</Form.Group>
											</Tab>
										</Tabs>
									</Col>
								</Row>}
								{(this.state.templateId === '1') && <Row>
									<Col sm={12}>
										<Form.Label>Upload Contacts</Form.Label>
										<Tabs defaultActiveKey="contactList" className="" onSelect={k => this.setUploadType(k, 'baseFileType')}>
											<Tab eventKey="contactList" title="Contact Numbers(Only 50K numbers can be copied)">
												<Form.Group className="form-group"  >
													<Form.Control onChange={e => this.setvalue(e)} name="contactList" as="textarea" rows="3" maxLength="549999" />
												</Form.Group>
											</Tab>
											<Tab eventKey="newBaseFile" title="Upload Contacts File(.csv OR .txt format only)">
												<Form.Group className="form-group"  >
													<Form.Control name="newContactsFile" onChange={this.onFileChangeHandler} ref={this.baseFileInput} type="file" />
												</Form.Group>
												<Form.Group className="form-group" >
													<Form.Control name="baseName" onChange={e => this.setvalue(e)} value={this.state.baseName} type="text" placeholder="Base File Name" />
												</Form.Group>
											</Tab>
											<Tab eventKey="oldBaseFile" title="Existing Contacts">
												<Form.Group className="form-group"  >
													{/* <Form.Label>Select File</Form.Label> */}
													<Form.Select as="select" name="oldBaseId" onChange={e => this.setvalue(e)} >
														<option value="">Select File</option>
														{baseUploadDropdown}
													</Form.Select>
												</Form.Group>
											</Tab>
										</Tabs>
									</Col>
								</Row>}
								{(this.state.templateId === '2') && <Row>
									<Col sm={12}>
										<Form.Label>Upload Contacts</Form.Label>
										<Tabs defaultActiveKey="contactList" className="" onSelect={k => this.setUploadType(k, 'baseFileType')}>
											<Tab eventKey="contactList" title="Contact Numbers(Only 50K numbers can be copied)">
												<Form.Group className="form-group"  >
													<Form.Control onChange={e => this.setvalue(e)} name="contactList" as="textarea" rows="3" maxLength="549999" />
												</Form.Group>
											</Tab>
											<Tab eventKey="newBaseFile" title="Upload Contacts File(.csv OR .txt format only)">
												<Form.Group className="form-group"  >
													<Form.Control name="newContactsFile" onChange={this.onFileChangeHandler} ref={this.baseFileInput} type="file" />
												</Form.Group>
												<Form.Group className="form-group" >
													<Form.Control name="baseName" onChange={e => this.setvalue(e)} value={this.state.baseName} type="text" placeholder="Base File Name" />
												</Form.Group>
											</Tab>
											<Tab eventKey="oldBaseFile" title="Existing Contacts">
												<Form.Group className="form-group"  >
													{/* <Form.Label>Select File</Form.Label> */}
													<Form.Select as="select" name="oldBaseId" onChange={e => this.setvalue(e)} >
														<option value="">Select File</option>
														{baseUploadDropdown}
													</Form.Select>
												</Form.Group>
											</Tab>
										</Tabs>
									</Col>
								</Row>}

                               {(this.state.templateId === '7' || this.state.templateId === '8' || this.state.templateId === '9') && <Row>
									<Col sm={12}>
										<Form.Label>Upload Contacts</Form.Label>
										<Tabs defaultActiveKey="contactList" className="" onSelect={k => this.setUploadType(k, 'baseFileType')}>
											<Tab eventKey="contactList" title="Contact Numbers(Only 50K numbers can be copied)">
												<Form.Group className="form-group"  >
													<Form.Control onChange={e => this.setvalue(e)} name="contactList" as="textarea" rows="3" maxLength="549999" />
												</Form.Group>
											</Tab>
											<Tab eventKey="newBaseFile" title="Upload Contacts File(.csv OR .txt format only)">
												<Form.Group className="form-group"  >
													<Form.Control name="newContactsFile" onChange={this.onFileChangeHandler} ref={this.baseFileInput} type="file" />
												</Form.Group>
												<Form.Group className="form-group" >
													<Form.Control name="baseName" onChange={e => this.setvalue(e)} value={this.state.baseName} type="text" placeholder="Base File Name" />
												</Form.Group>
											</Tab>
											<Tab eventKey="oldBaseFile" title="Existing Contacts">
												<Form.Group className="form-group"  >
													{/* <Form.Label>Select File</Form.Label> */}
													<Form.Select as="select" name="oldBaseId" onChange={e => this.setvalue(e)} >
														<option value="">Select File</option>
														{baseUploadDropdown}
													</Form.Select>
												</Form.Group>
											</Tab>
										</Tabs>
									</Col>
								</Row>}


								{(this.state.templateId === '3') && <Row className='mb-2'>
									<Col sm={12}>
										<Form.Label>Upload Contacts</Form.Label>
										<Tabs defaultActiveKey="contactList" className="" onSelect={k => this.setUploadType(k, 'baseFileType')}>
											<Tab eventKey="contactList" title="Contact Numbers(Only 50K numbers can be copied)">
												<Form.Group className="form-group"  >
													<Form.Control onChange={e => this.setvalue(e)} name="contactList" as="textarea" rows="3" maxLength="549999" />
												</Form.Group>
											</Tab>
											<Tab eventKey="newBaseFile" title="Upload Contacts File(.csv OR .txt format only)">
												<Form.Group className="form-group"  >
													<Form.Control name="newContactsFile" onChange={this.onFileChangeHandler} ref={this.baseFileInput} type="file" />
												</Form.Group>
												<Form.Group className="form-group" >
													<Form.Control name="baseName" onChange={e => this.setvalue(e)} value={this.state.baseName} type="text" placeholder="Base File Name" />
												</Form.Group>
											</Tab>
											<Tab eventKey="oldBaseFile" title="Existing Contacts">
												<Form.Group className="form-group"  >
													{/* <Form.Label>Select File</Form.Label> */}
													<Form.Select as="select" name="oldBaseId" onChange={e => this.setvalue(e)} >
														<option value="">Select File</option>
														{baseUploadDropdown}
													</Form.Select>
												</Form.Group>
											</Tab>
										</Tabs>
									</Col>
								</Row>}
								<Row>
									<Col sm={6}>
										<Form.Group className="form-group"  ></Form.Group>
									</Col>
								</Row>
								{(this.state.templateId === '1') && <Row>
									<Col sm={4}>
										<Form.Label>Upload Menu Wav File</Form.Label>
										<Form.Group className="form-group" >
											<Form.Select as="select" required name="menuId" onChange={e => this.setvalue(e)}>
												<option value="">Select File</option>
												{menuDropdown}
											</Form.Select>
										</Form.Group>
									</Col>
									<Col sm={4}>
											<Form.Label>Menu Wait Time</Form.Label>
											<Form.Group className="form-group" >
												<Form.Select required name="menuWaitTime" onChange={e => this.setvalue(e)} value={this.state.menuWaitTime} as="select">
													<option value="">Select Menu wait time</option>
													<option value="0">0</option>
													<option value="1">1</option>
													<option value="2">2</option>
													<option value="3">3</option>
													<option value="4">4</option>
													<option value="5">5</option>
													<option value="6">6</option>
													<option value="7">7</option>
													<option value="8">8</option>
													<option value="9">9</option>
												</Form.Select>
											</Form.Group>
									</Col>
									<Col sm={4}>
											<Form.Label>Re-Prompt</Form.Label>
											<Form.Group className="form-group" >
												<Form.Select required name="rePrompt" onChange={e => this.setvalue(e)} value={this.state.rePrompt} as="select">
													<option value="">Select Re-Prompt Count</option>
													<option value="0">0</option>
													<option value="1">1</option>
													<option value="2">2</option>
													<option value="3">3</option>
												</Form.Select>
											</Form.Group>
									</Col>
								</Row>}

								{(this.state.templateId === '8') && <Row>
									<Col sm={4}>
										<Form.Label>Upload Menu Wav File</Form.Label>
										<Form.Group className="form-group" >
											<Form.Select as="select" required name="menuId" onChange={e => this.setvalue(e)}>
												<option value="">Select File</option>
												{menuDropdown}
											</Form.Select>
										</Form.Group>
									</Col>
									<Col sm={4}>
											<Form.Label>Menu Wait Time</Form.Label>
											<Form.Group className="form-group" >
												<Form.Select required name="menuWaitTime" onChange={e => this.setvalue(e)} value={this.state.menuWaitTime} as="select">
													<option value="">Select Menu wait time</option>
													<option value="0">0</option>
													<option value="1">1</option>
													<option value="2">2</option>
													<option value="3">3</option>
													<option value="4">4</option>
													<option value="5">5</option>
													<option value="6">6</option>
													<option value="7">7</option>
													<option value="8">8</option>
													<option value="9">9</option>
												</Form.Select>
											</Form.Group>
									</Col>
									<Col sm={4}>
											<Form.Label>Re-Prompt</Form.Label>
											<Form.Group className="form-group" >
												<Form.Select required name="rePrompt" onChange={e => this.setvalue(e)} value={this.state.rePrompt} as="select">
													<option value="">Select Re-Prompt Count</option>
													<option value="0">0</option>
													<option value="1">1</option>
													<option value="2">2</option>
													<option value="3">3</option>
												</Form.Select>
											</Form.Group>
									</Col>
								</Row>}

                                {(this.state.templateId === '9') && <Row>
									<Col sm={4}>
										<Form.Label>Upload Menu Wav File</Form.Label>
										<Form.Group className="form-group" >
											<Form.Select as="select" required name="menuId" onChange={e => this.setvalue(e)}>
												<option value="">Select File</option>
												{menuDropdown}
											</Form.Select>
										</Form.Group>
									</Col>
									<Col sm={4}>
											<Form.Label>Menu Wait Time</Form.Label>
											<Form.Group className="form-group" >
												<Form.Select required name="menuWaitTime" onChange={e => this.setvalue(e)} value={this.state.menuWaitTime} as="select">
													<option value="">Select Menu wait time</option>
													<option value="0">0</option>
													<option value="1">1</option>
													<option value="2">2</option>
													<option value="3">3</option>
													<option value="4">4</option>
													<option value="5">5</option>
													<option value="6">6</option>
													<option value="7">7</option>
													<option value="8">8</option>
													<option value="9">9</option>
												</Form.Select>
											</Form.Group>
									</Col>
									<Col sm={4}>
											<Form.Label>Re-Prompt</Form.Label>
											<Form.Group className="form-group" >
												<Form.Select required name="rePrompt" onChange={e => this.setvalue(e)} value={this.state.rePrompt} as="select">
													<option value="">Select Re-Prompt Count</option>
													<option value="0">0</option>
													<option value="1">1</option>
													<option value="2">2</option>
													<option value="3">3</option>
												</Form.Select>
											</Form.Group>
									</Col>
								</Row>}


								{(this.state.templateId === '2') && <Row>
									<Col sm={4}>
										<Form.Label>Upload Menu Wav File</Form.Label>
										<Form.Group className="form-group" >
											<Form.Select as="select" required name="menuId" onChange={e => this.setvalue(e)}>
												<option value="">Select File</option>
												{menuDropdown}
											</Form.Select>
										</Form.Group>
									</Col>
									<Col sm={4}>
											<Form.Label>Menu Wait Time</Form.Label>
											<Form.Group className="form-group"  controlId="sender-id">
												<Form.Select required name="menuWaitTime" onChange={e => this.setvalue(e)} value={this.state.menuWaitTime} as="select">
													<option value="">Select Menu wait time</option>
													<option value="0">0</option>
													<option value="1">1</option>
													<option value="2">2</option>
													<option value="3">3</option>
													<option value="4">4</option>
													<option value="5">5</option>
													<option value="6">6</option>
													<option value="7">7</option>
													<option value="8">8</option>
													<option value="9">9</option>
												</Form.Select>
											</Form.Group>
									</Col>
									<Col sm={4}>
											<Form.Label>Re-Prompt</Form.Label>
											<Form.Group className="form-group" >
												<Form.Select required name="rePrompt" onChange={e => this.setvalue(e)} value={this.state.rePrompt} as="select">
													<option value="">Select Re-Prompt Count</option>
													<option value="0">0</option>
													<option value="1">1</option>
													<option value="2">2</option>
													<option value="3">3</option>
												</Form.Select>
											</Form.Group>
									</Col>
								</Row>}

								<Row>
									<Col sm={6}>
										<Form.Group className="form-group"  ></Form.Group>
									</Col>
								</Row>

								{this.state.templateId === '0' && <Row>
									<Col sm={6}>
										<Form.Label>Upload Wav File</Form.Label>
										<Form.Select as="select" required name="welcomeId" onChange={e => this.setvalue(e)}>
											<option value="">Select File</option>
											{welcomeDropdown}
										</Form.Select>
									</Col>
								</Row>}

								{(this.state.templateId === '1') && <Row>
									<Col sm={6}>
										<Form.Group className="form-group"  controlId="welcome-prompt">
											<Form.Check checked={this.state.welcomePrompt} onChange={(e) => this.toggleChange(e, 'welcomePrompt')} custom inline name="welcomePrompt" label="Welcome File" id="welcomePrompt" type="checkbox" />
											<Form.Check checked={this.state.noinputPrompt} onChange={(e) => this.toggleChange(e, 'noinputPrompt')} custom inline name="noinputPrompt" label="Noinput File" id="noinputPrompt" type="checkbox" />
											<Form.Check checked={this.state.wronginputPrompt} onChange={(e) => this.toggleChange(e, 'wronginputPrompt')} custom inline name="wronginputPrompt" label="Wronginput File" id="wronginputPrompt" type="checkbox" />
											<Form.Check checked={this.state.thanksPrompt} onChange={(e) => this.toggleChange(e, 'thanksPrompt')} custom inline name="thanksPrompt" label="Thanks File" id="thanksPrompt" type="checkbox" />
										</Form.Group>
									</Col>
								</Row>}

								{(this.state.templateId === '8') && <Row>
									<Col sm={6}>
										<Form.Group className="form-group"  controlId="welcome-prompt">
											{/* <Form.Check checked={this.state.welcomePrompt} onChange={(e) => this.toggleChange(e, 'welcomePrompt')} custom inline name="welcomePrompt" label="Welcome File" id="welcomePrompt" type="checkbox" /> */}
											<Form.Check checked={this.state.noinputPrompt} onChange={(e) => this.toggleChange(e, 'noinputPrompt')} custom inline name="noinputPrompt" label="Noinput File" id="noinputPrompt" type="checkbox" />
											<Form.Check checked={this.state.wronginputPrompt} onChange={(e) => this.toggleChange(e, 'wronginputPrompt')} custom inline name="wronginputPrompt" label="Wronginput File" id="wronginputPrompt" type="checkbox" />
											<Form.Check checked={this.state.thanksPrompt} onChange={(e) => this.toggleChange(e, 'thanksPrompt')} custom inline name="thanksPrompt" label="Thanks File" id="thanksPrompt" type="checkbox" />
										</Form.Group>
									</Col>
								</Row>}
								
								{(this.state.templateId === '2') && 
								<Row>
									<Col sm={6}>
										<Form.Group className="form-group"  controlId="welcome-prompt">
											<Form.Check checked={this.state.welcomePrompt} onChange={(e) => this.toggleChange(e, 'welcomePrompt')} custom inline name="welcomePrompt" label="Welcome File" id="welcomePrompt" type="checkbox" />
											<Form.Check checked={this.state.noinputPrompt} onChange={(e) => this.toggleChange(e, 'noinputPrompt')} custom inline name="noinputPrompt" label="Noinput File" id="noinputPrompt" type="checkbox" />
											<Form.Check checked={this.state.wronginputPrompt} onChange={(e) => this.toggleChange(e, 'wronginputPrompt')} custom inline name="wronginputPrompt" label="Wronginput File" id="wronginputPrompt" type="checkbox" />
											<Form.Check checked={this.state.thanksPrompt} onChange={(e) => this.toggleChange(e, 'thanksPrompt')} custom inline name="thanksPrompt" label="Thanks File" id="thanksPrompt" type="checkbox" />
											<Form.Check checked={this.state.noAgentPrompt} onChange={(e) => this.toggleChange(e, 'noAgentPrompt')} custom inline name="noAgentPrompt" label="No Agent File" id="noAgentPrompt" type="checkbox" />
										
										</Form.Group>
									</Col>
								</Row>}

								{(this.state.templateId === '9') && 
								<Row>
									<Col sm={6}>
										<Form.Group className="form-group"  controlId="welcome-prompt">
											{/* <Form.Check checked={this.state.welcomePrompt} onChange={(e) => this.toggleChange(e, 'welcomePrompt')} custom inline name="welcomePrompt" label="Welcome File" id="welcomePrompt" type="checkbox" /> */}
											<Form.Check checked={this.state.noinputPrompt} onChange={(e) => this.toggleChange(e, 'noinputPrompt')} custom inline name="noinputPrompt" label="Noinput File" id="noinputPrompt" type="checkbox" />
											<Form.Check checked={this.state.wronginputPrompt} onChange={(e) => this.toggleChange(e, 'wronginputPrompt')} custom inline name="wronginputPrompt" label="Wronginput File" id="wronginputPrompt" type="checkbox" />
											<Form.Check checked={this.state.thanksPrompt} onChange={(e) => this.toggleChange(e, 'thanksPrompt')} custom inline name="thanksPrompt" label="Thanks File" id="thanksPrompt" type="checkbox" />
											<Form.Check checked={this.state.noAgentPrompt} onChange={(e) => this.toggleChange(e, 'noAgentPrompt')} custom inline name="noAgentPrompt" label="No Agent File" id="noAgentPrompt" type="checkbox" />
										
										</Form.Group>
									</Col>
								</Row>}
								
								{(this.state.templateId === '3') && <Row>
									<Col sm={6}>
										<Form.Group className="form-group"  controlId="welcome-prompt">
											<Form.Check checked={this.state.noinputPrompt} onChange={(e) => this.toggleChange(e, 'noinputPrompt')} custom inline name="noinputPrompt" label="Noinput File" id="noinputPrompt" type="checkbox" />
											<Form.Check checked={this.state.wronginputPrompt} onChange={(e) => this.toggleChange(e, 'wronginputPrompt')} custom inline name="wronginputPrompt" label="Wronginput File" id="wronginputPrompt" type="checkbox" />
										</Form.Group>
									</Col>
								</Row>}


								{(this.state.welcomePrompt) && <Row>
									<Col sm={6}>
										<Form.Label>Upload Welcome Wav File</Form.Label>
										<Form.Group className="form-group" >
											<Form.Select as="select" name="welcomeId" onChange={e => this.setvalue(e)}>
												<option value="">Select File</option>
												{welcomeDropdown}
											</Form.Select>
										</Form.Group>
									</Col>
								</Row>}

								{(this.state.noinputPrompt) && <Row>
									<Col sm={6}>
										<Form.Label>Upload Noinput Wav File</Form.Label>

										<Form.Group className="form-group" >
											{/* <Form.Label>Select File</Form.Label> */}
											<Form.Select as="select" name="noinputId" onChange={e => this.setvalue(e)}>
												<option value="">Select File</option>
												{noinputDropdown}
											</Form.Select>
										</Form.Group>
									</Col>
								</Row>}

								{(this.state.wronginputPrompt) && <Row>
									<Col sm={6}>
										<Form.Label>Upload Wronginput Wav File</Form.Label>

										<Form.Group className="form-group" >
											{/* <Form.Label>Select File</Form.Label> */}
											<Form.Select as="select" name="wronginputId" onChange={e => this.setvalue(e)}>
												<option value="">Select File</option>
												{wronginputDropdown}
											</Form.Select>
										</Form.Group>

									</Col>
								</Row>}

								{(this.state.thanksPrompt) && <Row>
									<Col sm={6}>
										<Form.Label>Upload Thanks Wav File</Form.Label>

										<Form.Group className="form-group" >
											{/* <Form.Label>Select File</Form.Label> */}
											<Form.Select as="select" name="thanksId" onChange={e => this.setvalue(e)}>
												<option value="">Select File</option>
												{thanksDropdown}
											</Form.Select>
										</Form.Group>
									</Col>
								</Row>}

                                {(this.state.noAgentPrompt) && <Row>
									<Col sm={6}>
										<Form.Label>Upload No Agent Wav File</Form.Label>

										<Form.Group className="form-group" >
											{/* <Form.Label>Select File</Form.Label> */}
											<Form.Select as="select" name="noAgentId" onChange={e => this.setvalue(e)}>
												<option value="">Select File</option>
												{noAgentDropdown}
											</Form.Select>
										</Form.Group>

									</Col>
								</Row>}

								{(this.state.templateId === '2') && <Row>
									<Col sm={6}>
										<Form.Group className="form-group"  ><Form.Label>Add Agents</Form.Label></Form.Group>
									</Col>
								</Row>}

								{(this.state.templateId === '2') &&
									<Row>
										{/* <Col sm={2}>

											<Form.Group className="form-group"  controlId="campaign-name">
												<Form.Control required name="agentNumber" onChange={e => this.setvalue(e)} value={this.state.agentNumber} type="text" placeholder="Agent Number" />
											</Form.Group>
										</Col> */}

										<Col sm={6}>
										      <Form.Group className="form-group" >
											{/* <Form.Label>Select File</Form.Label> */}
											     <Form.Select as="select" name="groupId" onChange={e => this.setvalue(e)}>
												      <option value="">Select Group</option>
												           {groupAgentDropdown}
											    </Form.Select>
										      </Form.Group>

									    </Col>

										<Col sm={2}>
											<Form.Group className="form-group"  controlId="sender-id">
												<Form.Select required name="agentDtmf" onChange={e => this.setvalue(e)} value={this.state.agentDtmf} as="select">
													<option value="">Select DTMF</option>
													<option value="0">0</option>
													<option value="1">1</option>
													<option value="2">2</option>
													<option value="3">3</option>
													<option value="4">4</option>
													<option value="5">5</option>
													<option value="6">6</option>
													<option value="7">7</option>
													<option value="8">8</option>
													<option value="9">9</option>
													{/* <option value="*">*</option> */}
												</Form.Select>
											</Form.Group>
										</Col>
										<Col>
											<i onClick={this.handleAddRow} className="fa fa-plus-circle plus-icon"></i>
										</Col>
									</Row>}
								{(this.state.templateId === '2') && <Row>
									<Col sm={6}>
										<div className="react-bootstrap-table">

											<table className="table table-striped table-bordered table-sm widgetlist-table">
												<thead>
													<tr>
														<th>Group</th>
														<th>DTMF</th>
														<th>Action</th>
													</tr>
												</thead>
												<tbody>
													{agentsTable}
												</tbody>
											</table>
										</div>
									</Col>
								</Row>}


								{(this.state.templateId === '9' ) && <Row>
									<Col sm={6}>
										<Form.Group className="form-group"  ><Form.Label>Add Agents</Form.Label></Form.Group>
									</Col>
								</Row>}

								{(this.state.templateId === '9') &&
									<Row>
										
										<Col sm={6}>
										      <Form.Group className="form-group" >
											{/* <Form.Label>Select File</Form.Label> */}
											     <Form.Select as="select" name="groupId" onChange={e => this.setvalue(e)}>
												      <option value="">Select Group</option>
												           {groupAgentDropdown}
											    </Form.Select>
										      </Form.Group>

									    </Col>

										<Col sm={2}>
											<Form.Group className="form-group"  controlId="sender-id">
												<Form.Select required name="agentDtmf" onChange={e => this.setvalue(e)} value={this.state.agentDtmf} as="select">
													<option value="">Select DTMF</option>
													<option value="0">0</option>
													<option value="1">1</option>
													<option value="2">2</option>
													<option value="3">3</option>
													<option value="4">4</option>
													<option value="5">5</option>
													<option value="6">6</option>
													<option value="7">7</option>
													<option value="8">8</option>
													<option value="9">9</option>
													{/* <option value="*">*</option> */}
												</Form.Select>
											</Form.Group>
										</Col>
										<Col>
											<i onClick={this.handleAddRow} className="fa fa-plus-circle plus-icon"></i>
										</Col>
									</Row>}
								{(this.state.templateId === '9') && <Row>
									<Col sm={6}>
										<div className="react-bootstrap-table">

											<table className="table table-striped table-bordered table-sm widgetlist-table">
												<thead>
													<tr>
														<th>Group</th>
														<th>DTMF</th>
														<th>Action</th>
													</tr>
												</thead>
												<tbody>
													{agentsTable}
												</tbody>
											</table>
										</div>
									</Col>
								</Row>}
								
								
								{this.state.retryFlag && <Row>
									<Col sm={4}>
											<Form.Label>Retry Attempts</Form.Label>
											<Form.Group className="form-group" >
												<Form.Select name="retries" onChange={e => this.setvalue(e)} as="select">
													<option value="0">Retry Attempts</option>
													<option value="0">0</option>
													<option value="1">1</option>
													<option value="2">2</option>
												</Form.Select>
											</Form.Group>
									</Col>
									<Col sm={4}>
											<Form.Label>Retry Interval</Form.Label>
											<Form.Group className="form-group" >
												<Form.Select name="retryInterval" onChange={e => this.setvalue(e)} as="select">
													<option value="0">Retry Interval</option>
													<option value="900">15 Mins</option>
													<option value="1800">30 Mins</option>
												</Form.Select>
											</Form.Group>
									</Col>
								</Row>}
								{this.state.templateId === '0' && <Row>
									<Row>
										<Col sm={3}>
											<Form.Group className="form-group"  controlId="Send-Sms">
												<Form.Check checked={this.state.sendSmsSuccess} onChange={( e ) => this.toggleChange( e, 'sendSmsSuccess' )} custom inline name="sendSmsSuccess" label="Send SMS on Success" id="sendSmsSuccess" type="checkbox" />
											</Form.Group>
										</Col>
										{(this.state.sendSmsSuccess) && 
											<Col sm={3}>
												<Form.Group className="form-group"  controlId="sender-id">
													<Form.Select required name="callDurationSMS" onChange={e => this.setvalue(e)} value={this.state.callDurationSMS} as="select">
														<option value="">Select Duration</option>
														<option value="1">1</option>
														<option value="2">2</option>
														<option value="3">3</option>
														<option value="4">4</option>
														<option value="5">5</option>
														<option value="6">6</option>
														<option value="7">7</option>
														<option value="8">8</option>
														<option value="9">9</option>
														<option value="10">10</option>
														<option value="11">11</option>
														<option value="12">12</option>
														<option value="13">13</option>
														<option value="14">14</option>
														<option value="15">15</option>
														<option value="16">16</option>
														<option value="17">17</option>
														<option value="18">18</option>
														<option value="19">19</option>
														<option value="20">20</option>
														<option value="21">21</option>
														<option value="22">22</option>
														<option value="23">23</option>
														<option value="24">24</option>
														<option value="25">25</option>
														<option value="26">26</option>
														<option value="27">27</option>
														<option value="28">28</option>
														<option value="29">29</option>
														<option value="30">30</option>
														<option value="31">31</option>
														<option value="32">32</option>
														<option value="33">33</option>
														<option value="34">34</option>
														<option value="35">35</option>
														<option value="36">36</option>
														<option value="37">37</option>
														<option value="38">38</option>
														<option value="39">39</option>
														<option value="40">40</option>
														<option value="41">41</option>
														<option value="42">42</option>
														<option value="43">43</option>
														<option value="44">44</option>
														<option value="45">45</option>
														<option value="46">46</option>
														<option value="47">47</option>
														<option value="48">48</option>
														<option value="49">49</option>
														<option value="50">50</option>
														<option value="51">51</option>
														<option value="52">52</option>
														<option value="53">53</option>
														<option value="54">54</option>
														<option value="55">55</option>
														<option value="56">56</option>
														<option value="57">57</option>
														<option value="58">58</option>
														<option value="59">59</option>
														<option value="50">60</option>
													</Form.Select>
												</Form.Group>
										</Col>}
										{(this.state.sendSmsSuccess) && <Row>
											<Col sm={6}>
												<Form.Group className="form-group"  >
													<Form.Label> Note: Please Paste the SMS API here and replace the Mobile No. with &#123;PHONE&#125; </Form.Label>
													<Form.Control required value={this.state.smsApiSuccess} onChange={e => this.setvalue( e )} name="smsApiSuccess" as="textarea" rows="2" />
												</Form.Group>
											</Col>
										</Row>}
									</Row>
								</Row>}



								{this.state.templateId === '0'  && <Row>
									<Row>
										<Col sm={3}>
											<Form.Group className="form-group"  controlId="Send-Sms">
												<Form.Check checked={this.state.sendSmsFail} onChange={( e ) => this.toggleChange( e, 'sendSmsFail' )} custom inline name="sendSmsFail" label="Send SMS on Fail" id="sendSmsFail" type="checkbox" />
											</Form.Group>
										</Col>
									</Row>
									{(this.state.sendSmsFail) && <Row>
										<Col sm={6}>
											<Form.Group className="form-group"  >
											<Form.Label>Note: Please Paste the SMS API here and replace the Mobile No. with &#123;PHONE&#125; </Form.Label>
												<Form.Control required value={this.state.smsApiFail} onChange={e => this.setvalue( e )} name="smsApiFail" as="textarea" rows="2" />
											</Form.Group>
										</Col>
									</Row>}
								</Row>}


								{this.state.templateId === '7' && <Row>
									<Row>
										<Col sm={3}>
											<Form.Group className="form-group"  controlId="Send-Sms">
												<Form.Check checked={this.state.sendSmsSuccess} onChange={( e ) => this.toggleChange( e, 'sendSmsSuccess' )} custom inline name="sendSmsSuccess" label="Send SMS on Success" id="sendSmsSuccess" type="checkbox" />
											</Form.Group>
										</Col>
										{(this.state.sendSmsSuccess) && 
											<Col sm={3}>
												<Form.Group className="form-group"  controlId="sender-id">
													<Form.Select required name="callDurationSMS" onChange={e => this.setvalue(e)} value={this.state.callDurationSMS} as="select">
														<option value="">Select Duration</option>
														<option value="1">1</option>
														<option value="2">2</option>
														<option value="3">3</option>
														<option value="4">4</option>
														<option value="5">5</option>
														<option value="6">6</option>
														<option value="7">7</option>
														<option value="8">8</option>
														<option value="9">9</option>
														<option value="10">10</option>
														<option value="11">11</option>
														<option value="12">12</option>
														<option value="13">13</option>
														<option value="14">14</option>
														<option value="15">15</option>
														<option value="16">16</option>
														<option value="17">17</option>
														<option value="18">18</option>
														<option value="19">19</option>
														<option value="20">20</option>
														<option value="21">21</option>
														<option value="22">22</option>
														<option value="23">23</option>
														<option value="24">24</option>
														<option value="25">25</option>
														<option value="26">26</option>
														<option value="27">27</option>
														<option value="28">28</option>
														<option value="29">29</option>
														<option value="30">30</option>
														<option value="31">31</option>
														<option value="32">32</option>
														<option value="33">33</option>
														<option value="34">34</option>
														<option value="35">35</option>
														<option value="36">36</option>
														<option value="37">37</option>
														<option value="38">38</option>
														<option value="39">39</option>
														<option value="40">40</option>
														<option value="41">41</option>
														<option value="42">42</option>
														<option value="43">43</option>
														<option value="44">44</option>
														<option value="45">45</option>
														<option value="46">46</option>
														<option value="47">47</option>
														<option value="48">48</option>
														<option value="49">49</option>
														<option value="50">50</option>
														<option value="51">51</option>
														<option value="52">52</option>
														<option value="53">53</option>
														<option value="54">54</option>
														<option value="55">55</option>
														<option value="56">56</option>
														<option value="57">57</option>
														<option value="58">58</option>
														<option value="59">59</option>
														<option value="50">60</option>
													</Form.Select>
												</Form.Group>
										</Col>}
										{(this.state.sendSmsSuccess) && <Row>
											<Col sm={6}>
												<Form.Group className="form-group"  >
													<Form.Label> Note: Please Paste the SMS API here and replace the Mobile No. with &#123;PHONE&#125; </Form.Label>
													<Form.Control required value={this.state.smsApiSuccess} onChange={e => this.setvalue( e )} name="smsApiSuccess" as="textarea" rows="2" />
												</Form.Group>
											</Col>
										</Row>}
									</Row>
								</Row>}


								
								{this.state.templateId === '7'  && <Row>
									<Row>
										<Col sm={3}>
											<Form.Group className="form-group"  controlId="Send-Sms">
												<Form.Check checked={this.state.sendSmsFail} onChange={( e ) => this.toggleChange( e, 'sendSmsFail' )} custom inline name="sendSmsFail" label="Send SMS on Fail" id="sendSmsFail" type="checkbox" />
											</Form.Group>
										</Col>
									</Row>
									{(this.state.sendSmsFail) && <Row>
										<Col sm={6}>
											<Form.Group className="form-group"  >
											<Form.Label>Note: Please Paste the SMS API here and replace the Mobile No. with &#123;PHONE&#125; </Form.Label>
												<Form.Control required value={this.state.smsApiFail} onChange={e => this.setvalue( e )} name="smsApiFail" as="textarea" rows="2" />
											</Form.Group>
										</Col>
									</Row>}
								</Row>}
								
								{this.state.templateId === '1' && <Row>
									<Row>
										<Col sm={3}>
											<Form.Group className="form-group"  controlId="Send-Sms">
												<Form.Check checked={this.state.sendSmsSuccess} onChange={( e ) => this.toggleChange( e, 'sendSmsSuccess' )} custom inline name="sendSmsSuccess" label="Send SMS on Success" id="sendSmsSuccess" type="checkbox" />
											</Form.Group>
										</Col>
										{(this.state.sendSmsSuccess) && 
											<Col sm={3}>
												<Form.Group className="form-group"  controlId="sender-id">
													<Form.Select required name="callDurationSMS" onChange={e => this.setvalue(e)} value={this.state.callDurationSMS} as="select">
														<option value="">Select Duration</option>
														<option value="1">1</option>
														<option value="2">2</option>
														<option value="3">3</option>
														<option value="4">4</option>
														<option value="5">5</option>
														<option value="6">6</option>
														<option value="7">7</option>
														<option value="8">8</option>
														<option value="9">9</option>
														<option value="10">10</option>
														<option value="11">11</option>
														<option value="12">12</option>
														<option value="13">13</option>
														<option value="14">14</option>
														<option value="15">15</option>
														<option value="16">16</option>
														<option value="17">17</option>
														<option value="18">18</option>
														<option value="19">19</option>
														<option value="20">20</option>
														<option value="21">21</option>
														<option value="22">22</option>
														<option value="23">23</option>
														<option value="24">24</option>
														<option value="25">25</option>
														<option value="26">26</option>
														<option value="27">27</option>
														<option value="28">28</option>
														<option value="29">29</option>
														<option value="30">30</option>
														<option value="31">31</option>
														<option value="32">32</option>
														<option value="33">33</option>
														<option value="34">34</option>
														<option value="35">35</option>
														<option value="36">36</option>
														<option value="37">37</option>
														<option value="38">38</option>
														<option value="39">39</option>
														<option value="40">40</option>
														<option value="41">41</option>
														<option value="42">42</option>
														<option value="43">43</option>
														<option value="44">44</option>
														<option value="45">45</option>
														<option value="46">46</option>
														<option value="47">47</option>
														<option value="48">48</option>
														<option value="49">49</option>
														<option value="50">50</option>
														<option value="51">51</option>
														<option value="52">52</option>
														<option value="53">53</option>
														<option value="54">54</option>
														<option value="55">55</option>
														<option value="56">56</option>
														<option value="57">57</option>
														<option value="58">58</option>
														<option value="59">59</option>
														<option value="50">60</option>
													</Form.Select>
												</Form.Group>
										</Col>}
										{(this.state.sendSmsSuccess) && <Row>
										<Col sm={6}>
											<Form.Group className="form-group"  >
											<Form.Label>Note: Please Paste the SMS API here and replace the Mobile No. with &#123;PHONE&#125; </Form.Label>
												<Form.Control required value={this.state.smsApiSuccess} onChange={e => this.setvalue( e )} name="smsApiSuccess" as="textarea" rows="2" />
											</Form.Group>
										</Col>
										
										</Row>}
									</Row>
								</Row>}
								{this.state.templateId === '1'  && <Row>
									<Row>
										<Col sm={3}>
											<Form.Group className="form-group"  controlId="Send-Sms">
												<Form.Check checked={this.state.sendSmsFail} onChange={( e ) => this.toggleChange( e, 'sendSmsFail' )} custom inline name="sendSmsFail" label="Send SMS on Fail" id="sendSmsFail" type="checkbox" />
											</Form.Group>
										</Col>
									</Row>
									{(this.state.sendSmsFail) && <Row>
										<Col sm={6}>
											<Form.Group className="form-group"  >
											<Form.Label>Note: Please Paste the SMS API here and replace the Mobile No. with &#123;PHONE&#125; </Form.Label>
												<Form.Control value={this.state.smsApiFail} onChange={e => this.setvalue( e )} name="smsApiFail" as="textarea" rows="2" />
											</Form.Group>
										</Col>
										
									</Row>}
								</Row>}
								{this.state.templateId === '1'  && <Row>
									<Row>
										<Col sm={3}>
											<Form.Group className="form-group"  controlId="Send-Sms">
												<Form.Check checked={this.state.sendSmsDtmf} onChange={( e ) => this.toggleChange( e, 'sendSmsDtmf' )} custom inline name="sendSmsDtmf" label="Send SMS on DTMF" id="sendSmsDtmf" type="checkbox" />
											</Form.Group>
										</Col>
									</Row>
									{(this.state.sendSmsDtmf) &&<Row>
										<Col sm={6}>
											<Form.Group className="form-group"  >
											<Form.Label>Note: Please Paste the SMS API here and replace the Mobile No. with &#123;PHONE&#125; </Form.Label>
												<Form.Control required value={this.state.smsApiDtmf} onChange={e => this.setvalue( e )} name="smsApiDtmf" as="textarea" rows="2" />
											</Form.Group>
										</Col>
										
									</Row>}
								</Row>}

							  {this.state.templateId === '8' && <Row>
									<Row>
										<Col sm={3}>
											<Form.Group className="form-group"  controlId="Send-Sms">
												<Form.Check checked={this.state.sendSmsSuccess} onChange={( e ) => this.toggleChange( e, 'sendSmsSuccess' )} custom inline name="sendSmsSuccess" label="Send SMS on Success" id="sendSmsSuccess" type="checkbox" />
											</Form.Group>
										</Col>
										{(this.state.sendSmsSuccess) && 
											<Col sm={3}>
												<Form.Group className="form-group"  controlId="sender-id">
													<Form.Select required name="callDurationSMS" onChange={e => this.setvalue(e)} value={this.state.callDurationSMS} as="select">
														<option value="">Select Duration</option>
														<option value="1">1</option>
														<option value="2">2</option>
														<option value="3">3</option>
														<option value="4">4</option>
														<option value="5">5</option>
														<option value="6">6</option>
														<option value="7">7</option>
														<option value="8">8</option>
														<option value="9">9</option>
														<option value="10">10</option>
														<option value="11">11</option>
														<option value="12">12</option>
														<option value="13">13</option>
														<option value="14">14</option>
														<option value="15">15</option>
														<option value="16">16</option>
														<option value="17">17</option>
														<option value="18">18</option>
														<option value="19">19</option>
														<option value="20">20</option>
														<option value="21">21</option>
														<option value="22">22</option>
														<option value="23">23</option>
														<option value="24">24</option>
														<option value="25">25</option>
														<option value="26">26</option>
														<option value="27">27</option>
														<option value="28">28</option>
														<option value="29">29</option>
														<option value="30">30</option>
														<option value="31">31</option>
														<option value="32">32</option>
														<option value="33">33</option>
														<option value="34">34</option>
														<option value="35">35</option>
														<option value="36">36</option>
														<option value="37">37</option>
														<option value="38">38</option>
														<option value="39">39</option>
														<option value="40">40</option>
														<option value="41">41</option>
														<option value="42">42</option>
														<option value="43">43</option>
														<option value="44">44</option>
														<option value="45">45</option>
														<option value="46">46</option>
														<option value="47">47</option>
														<option value="48">48</option>
														<option value="49">49</option>
														<option value="50">50</option>
														<option value="51">51</option>
														<option value="52">52</option>
														<option value="53">53</option>
														<option value="54">54</option>
														<option value="55">55</option>
														<option value="56">56</option>
														<option value="57">57</option>
														<option value="58">58</option>
														<option value="59">59</option>
														<option value="50">60</option>
													</Form.Select>
												</Form.Group>
										</Col>}
										{(this.state.sendSmsSuccess) && <Row>
										<Col sm={6}>
											<Form.Group className="form-group"  >
											<Form.Label>Note: Please Paste the SMS API here and replace the Mobile No. with &#123;PHONE&#125; </Form.Label>
												<Form.Control required value={this.state.smsApiSuccess} onChange={e => this.setvalue( e )} name="smsApiSuccess" as="textarea" rows="2" />
											</Form.Group>
										</Col>
										
										</Row>}
									</Row>
								</Row>}
								{this.state.templateId === "8"  && <Row>
									<Row>
										<Col sm={3}>
											<Form.Group className="form-group"  controlId="Send-Sms">
												<Form.Check checked={this.state.sendSmsFail} onChange={( e ) => this.toggleChange( e, 'sendSmsFail' )} custom inline name="sendSmsFail" label="Send SMS on Fail" id="sendSmsFail" type="checkbox" />
											</Form.Group>
										</Col>
									</Row>
									{(this.state.sendSmsFail) && <Row>
										<Col sm={6}>
											<Form.Group className="form-group"  >
											<Form.Label>Note: Please Paste the SMS API here and replace the Mobile No. with &#123;PHONE&#125; </Form.Label>
												<Form.Control value={this.state.smsApiFail} onChange={e => this.setvalue( e )} name="smsApiFail" as="textarea" rows="2" />
											</Form.Group>
										</Col>
										
									</Row>}
								</Row>}
								{this.state.templateId === "8"  && <Row>
									<Row>
										<Col sm={3}>
											<Form.Group className="form-group"  controlId="Send-Sms">
												<Form.Check checked={this.state.sendSmsDtmf} onChange={( e ) => this.toggleChange( e, 'sendSmsDtmf' )} custom inline name="sendSmsDtmf" label="Send SMS on DTMF" id="sendSmsDtmf" type="checkbox" />
											</Form.Group>
										</Col>
									</Row>
									{(this.state.sendSmsDtmf) &&<Row>
										<Col sm={6}>
											<Form.Group className="form-group"  >
											<Form.Label>Note: Please Paste the SMS API here and replace the Mobile No. with &#123;PHONE&#125; </Form.Label>
												<Form.Control required value={this.state.smsApiDtmf} onChange={e => this.setvalue( e )} name="smsApiDtmf" as="textarea" rows="2" />
											</Form.Group>
										</Col>
										
									</Row>}
								</Row>}


								{this.state.templateId === '2' && <Row>
									<Row>
										<Col sm={3}>
											<Form.Group className="form-group"  controlId="Send-Sms">
												<Form.Check checked={this.state.sendSmsSuccess} onChange={( e ) => this.toggleChange( e, 'sendSmsSuccess' )} custom inline name="sendSmsSuccess" label="Send SMS on Success" id="sendSmsSuccess" type="checkbox" />
											</Form.Group>
										</Col>
										{(this.state.sendSmsSuccess) && 
											<Col sm={3}>
												<Form.Group className="form-group"  controlId="sender-id">
													<Form.Select required name="callDurationSMS" onChange={e => this.setvalue(e)} value={this.state.callDurationSMS} as="select">
														<option value="">Select Duration</option>
														<option value="1">1</option>
														<option value="2">2</option>
														<option value="3">3</option>
														<option value="4">4</option>
														<option value="5">5</option>
														<option value="6">6</option>
														<option value="7">7</option>
														<option value="8">8</option>
														<option value="9">9</option>
														<option value="10">10</option>
														<option value="11">11</option>
														<option value="12">12</option>
														<option value="13">13</option>
														<option value="14">14</option>
														<option value="15">15</option>
														<option value="16">16</option>
														<option value="17">17</option>
														<option value="18">18</option>
														<option value="19">19</option>
														<option value="20">20</option>
														<option value="21">21</option>
														<option value="22">22</option>
														<option value="23">23</option>
														<option value="24">24</option>
														<option value="25">25</option>
														<option value="26">26</option>
														<option value="27">27</option>
														<option value="28">28</option>
														<option value="29">29</option>
														<option value="30">30</option>
														<option value="31">31</option>
														<option value="32">32</option>
														<option value="33">33</option>
														<option value="34">34</option>
														<option value="35">35</option>
														<option value="36">36</option>
														<option value="37">37</option>
														<option value="38">38</option>
														<option value="39">39</option>
														<option value="40">40</option>
														<option value="41">41</option>
														<option value="42">42</option>
														<option value="43">43</option>
														<option value="44">44</option>
														<option value="45">45</option>
														<option value="46">46</option>
														<option value="47">47</option>
														<option value="48">48</option>
														<option value="49">49</option>
														<option value="50">50</option>
														<option value="51">51</option>
														<option value="52">52</option>
														<option value="53">53</option>
														<option value="54">54</option>
														<option value="55">55</option>
														<option value="56">56</option>
														<option value="57">57</option>
														<option value="58">58</option>
														<option value="59">59</option>
														<option value="50">60</option>
													</Form.Select>
												</Form.Group>
										</Col>}
									</Row>


                                    



									{(this.state.sendSmsSuccess) && <Row>
										<Col sm={6}>
											<Form.Group className="form-group"  >
											<Form.Label>Note: Please Paste the SMS API here and replace the Mobile No. with &#123;PHONE&#125; </Form.Label>
												<Form.Control required value={this.state.smsApiSuccess} onChange={e => this.setvalue( e )} name="smsApiSuccess" as="textarea" rows="2" />
											</Form.Group>
										</Col>
										
									</Row>}
								</Row>}

                        
								{this.state.templateId === '2'  && <Row>
									<Row>
										<Col sm={3}>
											<Form.Group className="form-group"  controlId="Send-Sms">
												<Form.Check checked={this.state.sendSmsFail} onChange={( e ) => this.toggleChange( e, 'sendSmsFail' )} custom inline name="sendSmsFail" label="Send SMS on Fail" id="sendSmsFail" type="checkbox" />
											</Form.Group>
										</Col>
									</Row>	
									{(this.state.sendSmsFail) && <Row>
										<Col sm={6}>
											<Form.Group className="form-group"  >
											<Form.Label>Note: Please Paste the SMS API here and replace the Mobile No. with &#123;PHONE&#125; </Form.Label>
												<Form.Control required value={this.state.smsApiFail} onChange={e => this.setvalue( e )} name="smsApiFail" as="textarea" rows="2" />
											</Form.Group>
										</Col>
										
									</Row>}
								</Row>}

                                
								{this.state.templateId === '2' && <Row>
									<Row>
										<Col sm={3}>
											<Form.Group className="form-group"  controlId="Send-Sms">
												<Form.Check checked={this.state.sendSmsPatchSuccess} onChange={( e ) => this.toggleChange( e, 'sendSmsPatchSuccess' )} custom inline name="sendSmsPatchSuccess" label="Send SMS on Patch Success" id="sendSmsPatchSuccess" type="checkbox" />
											</Form.Group>
										</Col>
										
									</Row>



									{(this.state.sendSmsPatchSuccess) && <Row>
										<Col sm={6}>
											<Form.Group className="form-group"  >
											<Form.Label>Note: Please Paste the SMS API here and replace the Mobile No. with &#123;PHONE&#125; </Form.Label>
												<Form.Control required value={this.state.callPatchSuccessMessage} onChange={e => this.setvalue( e )} name="callPatchSuccessMessage" as="textarea" rows="2" />
											</Form.Group>
										</Col>
										
									</Row>}
								</Row>}

								{this.state.templateId === '2'  && <Row>
									<Row>
										<Col sm={3}>
											<Form.Group className="form-group"  controlId="Send-Sms">
												<Form.Check checked={this.state.sendSmsPatchFail} onChange={( e ) => this.toggleChange( e, 'sendSmsPatchFail' )} custom inline name="sendSmsPatchFail" label="Send SMS on Patch Fail" id="sendSmsPatchFail" type="checkbox" />
											</Form.Group>
										</Col>
									</Row>	
									{(this.state.sendSmsPatchFail) && <Row>
										<Col sm={6}>
											<Form.Group className="form-group"  >
											<Form.Label>Note: Please Paste the SMS API here and replace the Mobile No. with &#123;PHONE&#125; </Form.Label>
												<Form.Control required value={this.state.callPatchFailMessage} onChange={e => this.setvalue( e )} name="callPatchFailMessage" as="textarea" rows="2" />
											</Form.Group>
										</Col>
										
									</Row>}
								</Row>}


								{this.state.templateId === '2'  && <Row>
									<Row>
										<Col sm={3}>
											<Form.Group className="form-group"  controlId="Send-Sms">
												<Form.Check checked={this.state.sendSmsDtmf} onChange={( e ) => this.toggleChange( e, 'sendSmsDtmf' )} custom inline name="sendSmsDtmf" label="Send SMS on DTMF" id="sendSmsDtmf" type="checkbox" />
											</Form.Group>
										</Col>
									</Row>
									{(this.state.sendSmsDtmf) && <Row>
										<Col sm={6}>
											<Form.Group className="form-group"  >
											<Form.Label> Note: Please Paste the SMS API here and replace the Mobile No. with &#123;PHONE&#125; </Form.Label>
												<Form.Control required value={this.state.smsApiDtmf} onChange={e => this.setvalue( e )} name="smsApiDtmf" as="textarea" rows="2" />
											</Form.Group>
										</Col>
										
									</Row>}
								</Row>}

                                
								
								{this.state.templateId === '9' && <Row>
									<Row>
										<Col sm={3}>
											<Form.Group className="form-group"  controlId="Send-Sms">
												<Form.Check checked={this.state.sendSmsSuccess} onChange={( e ) => this.toggleChange( e, 'sendSmsSuccess' )} custom inline name="sendSmsSuccess" label="Send SMS on Success" id="sendSmsSuccess" type="checkbox" />
											</Form.Group>
										</Col>
										{(this.state.sendSmsSuccess) && 
											<Col sm={3}>
												<Form.Group className="form-group"  controlId="sender-id">
													<Form.Select required name="callDurationSMS" onChange={e => this.setvalue(e)} value={this.state.callDurationSMS} as="select">
														<option value="">Select Duration</option>
														<option value="1">1</option>
														<option value="2">2</option>
														<option value="3">3</option>
														<option value="4">4</option>
														<option value="5">5</option>
														<option value="6">6</option>
														<option value="7">7</option>
														<option value="8">8</option>
														<option value="9">9</option>
														<option value="10">10</option>
														<option value="11">11</option>
														<option value="12">12</option>
														<option value="13">13</option>
														<option value="14">14</option>
														<option value="15">15</option>
														<option value="16">16</option>
														<option value="17">17</option>
														<option value="18">18</option>
														<option value="19">19</option>
														<option value="20">20</option>
														<option value="21">21</option>
														<option value="22">22</option>
														<option value="23">23</option>
														<option value="24">24</option>
														<option value="25">25</option>
														<option value="26">26</option>
														<option value="27">27</option>
														<option value="28">28</option>
														<option value="29">29</option>
														<option value="30">30</option>
														<option value="31">31</option>
														<option value="32">32</option>
														<option value="33">33</option>
														<option value="34">34</option>
														<option value="35">35</option>
														<option value="36">36</option>
														<option value="37">37</option>
														<option value="38">38</option>
														<option value="39">39</option>
														<option value="40">40</option>
														<option value="41">41</option>
														<option value="42">42</option>
														<option value="43">43</option>
														<option value="44">44</option>
														<option value="45">45</option>
														<option value="46">46</option>
														<option value="47">47</option>
														<option value="48">48</option>
														<option value="49">49</option>
														<option value="50">50</option>
														<option value="51">51</option>
														<option value="52">52</option>
														<option value="53">53</option>
														<option value="54">54</option>
														<option value="55">55</option>
														<option value="56">56</option>
														<option value="57">57</option>
														<option value="58">58</option>
														<option value="59">59</option>
														<option value="50">60</option>
													</Form.Select>
												</Form.Group>
										</Col>}
									</Row>
									{(this.state.sendSmsSuccess) && <Row>
										<Col sm={6}>
											<Form.Group className="form-group"  >
											<Form.Label>Note: Please Paste the SMS API here and replace the Mobile No. with &#123;PHONE&#125; </Form.Label>
												<Form.Control required value={this.state.smsApiSuccess} onChange={e => this.setvalue( e )} name="smsApiSuccess" as="textarea" rows="2" />
											</Form.Group>
										</Col>
										
									</Row>}
								</Row>}
								{this.state.templateId === '9'  && <Row>
									<Row>
										<Col sm={3}>
											<Form.Group className="form-group"  controlId="Send-Sms">
												<Form.Check checked={this.state.sendSmsFail} onChange={( e ) => this.toggleChange( e, 'sendSmsFail' )} custom inline name="sendSmsFail" label="Send SMS on Fail" id="sendSmsFail" type="checkbox" />
											</Form.Group>
										</Col>
									</Row>	
									{(this.state.sendSmsFail) && <Row>
										<Col sm={6}>
											<Form.Group className="form-group"  >
											<Form.Label>Note: Please Paste the SMS API here and replace the Mobile No. with &#123;PHONE&#125; </Form.Label>
												<Form.Control required value={this.state.smsApiFail} onChange={e => this.setvalue( e )} name="smsApiFail" as="textarea" rows="2" />
											</Form.Group>
										</Col>
										
									</Row>}
								</Row>}

                                
								{this.state.templateId === '9' && <Row>
									<Row>
										<Col sm={3}>
											<Form.Group className="form-group"  controlId="Send-Sms">
												<Form.Check checked={this.state.sendSmsPatchSuccess} onChange={( e ) => this.toggleChange( e, 'sendSmsPatchSuccess' )} custom inline name="sendSmsPatchSuccess" label="Send SMS on Patch Success" id="sendSmsPatchSuccess" type="checkbox" />
											</Form.Group>
										</Col>
										
									</Row>



									{(this.state.sendSmsPatchSuccess) && <Row>
										<Col sm={6}>
											<Form.Group className="form-group"  >
											<Form.Label>Note: Please Paste the SMS API here and replace the Mobile No. with &#123;PHONE&#125; </Form.Label>
												<Form.Control required value={this.state.callPatchSuccessMessage} onChange={e => this.setvalue( e )} name="callPatchSuccessMessage" as="textarea" rows="2" />
											</Form.Group>
										</Col>
										
									</Row>}
								</Row>}

								{this.state.templateId === '9'  && <Row>
									<Row>
										<Col sm={3}>
											<Form.Group className="form-group"  controlId="Send-Sms">
												<Form.Check checked={this.state.sendSmsPatchFail} onChange={( e ) => this.toggleChange( e, 'sendSmsPatchFail' )} custom inline name="sendSmsPatchFail" label="Send SMS on Patch Fail" id="sendSmsPatchFail" type="checkbox" />
											</Form.Group>
										</Col>
									</Row>	
									{(this.state.sendSmsPatchFail) && <Row>
										<Col sm={6}>
											<Form.Group className="form-group"  >
											<Form.Label>Note: Please Paste the SMS API here and replace the Mobile No. with &#123;PHONE&#125; </Form.Label>
												<Form.Control required value={this.state.callPatchFailMessage} onChange={e => this.setvalue( e )} name="callPatchFailMessage" as="textarea" rows="2" />
											</Form.Group>
										</Col>
										
									</Row>}
								</Row>}
                                
                                


								{this.state.templateId === '9'  && <Row>
									<Row>
										<Col sm={3}>
											<Form.Group className="form-group"  controlId="Send-Sms">
												<Form.Check checked={this.state.sendSmsDtmf} onChange={( e ) => this.toggleChange( e, 'sendSmsDtmf' )} custom inline name="sendSmsDtmf" label="Send SMS on DTMF" id="sendSmsDtmf" type="checkbox" />
											</Form.Group>
										</Col>
									</Row>
									{(this.state.sendSmsDtmf) && <Row>
										<Col sm={6}>
											<Form.Group className="form-group"  >
											<Form.Label> Note: Please Paste the SMS API here and replace the Mobile No. with &#123;PHONE&#125; </Form.Label>
												<Form.Control required value={this.state.smsApiDtmf} onChange={e => this.setvalue( e )} name="smsApiDtmf" as="textarea" rows="2" />
											</Form.Group>
										</Col>
										
									</Row>}
								</Row>}
								
								{this.state.templateId === '3' && <Row>
									<Row>
										<Col sm={3}>
											<Form.Group className="form-group"  controlId="Send-Sms">
												<Form.Check checked={this.state.sendSmsSuccess} onChange={( e ) => this.toggleChange( e, 'sendSmsSuccess' )} custom inline name="sendSmsSuccess" label="Send SMS on Success" id="sendSmsSuccess" type="checkbox" />
											</Form.Group>
										</Col>
										{(this.state.sendSmsSuccess) && 
											<Col sm={3}>
												<Form.Group className="form-group"  controlId="sender-id">
													<Form.Select required name="callDurationSMS" onChange={e => this.setvalue(e)} value={this.state.callDurationSMS} as="select">
														<option value="">Select Duration</option>
														<option value="1">1</option>
														<option value="2">2</option>
														<option value="3">3</option>
														<option value="4">4</option>
														<option value="5">5</option>
														<option value="6">6</option>
														<option value="7">7</option>
														<option value="8">8</option>
														<option value="9">9</option>
														<option value="10">10</option>
														<option value="11">11</option>
														<option value="12">12</option>
														<option value="13">13</option>
														<option value="14">14</option>
														<option value="15">15</option>
														<option value="16">16</option>
														<option value="17">17</option>
														<option value="18">18</option>
														<option value="19">19</option>
														<option value="20">20</option>
														<option value="21">21</option>
														<option value="22">22</option>
														<option value="23">23</option>
														<option value="24">24</option>
														<option value="25">25</option>
														<option value="26">26</option>
														<option value="27">27</option>
														<option value="28">28</option>
														<option value="29">29</option>
														<option value="30">30</option>
														<option value="31">31</option>
														<option value="32">32</option>
														<option value="33">33</option>
														<option value="34">34</option>
														<option value="35">35</option>
														<option value="36">36</option>
														<option value="37">37</option>
														<option value="38">38</option>
														<option value="39">39</option>
														<option value="40">40</option>
														<option value="41">41</option>
														<option value="42">42</option>
														<option value="43">43</option>
														<option value="44">44</option>
														<option value="45">45</option>
														<option value="46">46</option>
														<option value="47">47</option>
														<option value="48">48</option>
														<option value="49">49</option>
														<option value="50">50</option>
														<option value="51">51</option>
														<option value="52">52</option>
														<option value="53">53</option>
														<option value="54">54</option>
														<option value="55">55</option>
														<option value="56">56</option>
														<option value="57">57</option>
														<option value="58">58</option>
														<option value="59">59</option>
														<option value="50">60</option>
													</Form.Select>
												</Form.Group>
										</Col>}
									</Row>
									{(this.state.sendSmsSuccess) && <Row>
										<Col sm={6}>
											<Form.Group className="form-group"  >
											<Form.Label> Note: Please Paste the SMS API here and replace the Mobile No. with &#123;PHONE&#125; </Form.Label>
												<Form.Control required value={this.state.smsApiSuccess} onChange={e => this.setvalue( e )} name="smsApiSuccess" as="textarea" rows="2" />
											</Form.Group>
										</Col>
										
									</Row>}
								</Row>}
								{this.state.templateId === '3'  && <Row>
									<Row>
										<Col sm={3}>
											<Form.Group className="form-group"  controlId="Send-Sms">
												<Form.Check checked={this.state.sendSmsFail} onChange={( e ) => this.toggleChange( e, 'sendSmsFail' )} custom inline name="sendSmsFail" label="Send SMS on Fail" id="sendSmsFail" type="checkbox" />
											</Form.Group>
										</Col>
									</Row>
								{(this.state.sendSmsFail) && <Row>
										<Col sm={6}>
											<Form.Group className="form-group"  >
											<Form.Label>Note: Please Paste the SMS API here and replace the Mobile No. with &#123;PHONE&#125; </Form.Label>
												<Form.Control required value={this.state.smsApiFail} onChange={e => this.setvalue( e )} name="smsApiFail" as="textarea" rows="2" />
											</Form.Group>
										</Col>
										
									</Row>}
								</Row>}
								<Row>
									<Col sm={6}>
										<Form.Group className="form-group"  controlId="Send-later">
											<Form.Check checked={this.state.sendlater} onChange={(e) => this.toggleChange(e, 'sendLater')} custom inline name="send-later" label="Schedule Call" id="send-later" type="checkbox" />
										</Form.Group>
									</Col>
								</Row>
								{sendLaterDatepicker}


                               {(this.state.webhookFlag) && 
								<Row>
									<Col sm={3}>
										<Form.Group controlId="Send-later">
											<Form.Check checked={this.state.sendWebhook} onChange={(e) => this.toggleChange(e, 'sendWebhook')} custom inline name="send-webhook" label="Enable Webhook" id="send-webhook" type="checkbox" />
										</Form.Group>
									</Col>

									{(this.state.sendWebhook) && 
										<Col sm={3}>
											<Form.Group controlId="sender-id">
												<Form.Select required name="webhookId" onChange={e => this.setvalue(e)} value={this.state.webhookId} as="select">
													<option value="">Select Webhook</option>
												{webhookDropdown}
												</Form.Select>
											</Form.Group>
									</Col>}
								</Row>
	                            }


								<Button variant="primary" type="submit" disabled={this.state.loading}>{submitButtonlabel}</Button>
							</Card.Body>
						</Card>

					</Col>
				</Row>
			</Form>
		);
	}
}

export default AddVoiceCampaign;