import React, { Component } from 'react';
import { Card, Modal, Button,Col, Form, Row } from 'react-bootstrap';
import ReactPlayer from 'react-player';
import BootstrapTable from 'react-bootstrap-table-next';
import ToolkitProvider, { Search,CSVExport } from 'react-bootstrap-table2-toolkit';
import paginationFactory, { PaginationProvider, PaginationListStandalone, SizePerPageDropdownStandalone } from 'react-bootstrap-table2-paginator';
import { Fetcher } from "../../Helpers/fetcher.js";
import 'react-bootstrap-table-next/dist/react-bootstrap-table2.min.css';
import 'react-bootstrap-table2-toolkit/dist/react-bootstrap-table2-toolkit.min.css';
import 'react-bootstrap-table2-paginator/dist/react-bootstrap-table2-paginator.min.css';
import './index.css';
import { Link } from 'react-router-dom';
import Cookies from 'js-cookie';
import DatePicker from "react-datepicker";

import { Notify } from "../../Helpers/notification.js";

class SubUserReport extends Component {
	constructor( props ) {

		super( props );
		this.state = {
			reportList: [],
			sizePerPage: 10,
			showListenModal: false,
			modalHeading: '',
			modalContent: '',
			selectedPromptUrl: '',
			copied: true,
			startDate: '',
			endDate : '',
			campaignName : '',
			campaignType : '',
			mobile : '',
			searchpatchDuration : '',
			pd : ''
		}
	}
	componentDidMount() {
		const savedSizePerPage = Cookies.get('downloadReportListsizePerPage');
		if (savedSizePerPage) {
			this.setState({ sizePerPage: Number(savedSizePerPage) });
		}
		const userId = this.props.app.userId;
        // this.props.app.apiPath
		// Fetcher.get(this.props.app.apiPath + '/api/obd/sub/user/report/list/' + userId)
		// 	.then(res => {
		// 		this.setState({
		// 			reportList: res
		// 		})
		// 	})
		// 	.catch(err => { console.log('Error in fetching Report', err) });
        
		let sDate = "";
		let eDate =""
		if(this.state.startDate ===""){
             sDate = "";
		}else{
			 sDate = this.formatDate(this.state.startDate);
		}

		if(this.state.endDate ===""){
             eDate = "";
		}else{
			 eDate = this.formatDate(this.state.endDate);
		}


		const data = {
				userId : userId,
				startDate : sDate,
				endDate : eDate,
				campaignName : this.state.campaignName,
				campaignType : this.state.campaignType,
				mobile : this.state.mobile,
				patchDurationOperation : ''
		}

		// http://localhost:8080
		// this.props.app.apiPath
		Fetcher.post(this.props.app.apiPath + '/api/obd/sub/user/report/list',{ headers: { "Content-type": "application/json" }, method: 'POST', body: JSON.stringify(data) })
			.then(res => {
				this.setState({
					reportList: res
				})
			})
			.catch(err => { console.log('Error in fetching sub user report', err) });
	}

	formatDate = (date) => {
		console.log("hereee");
		const year = date.getFullYear();
		const month = String(date.getMonth() + 1).padStart(2, '0');
		const day = String(date.getDate()).padStart(2, '0');
	
		return `${year}-${month}-${day}`;
	};


	setvalue = (e) => {
		this.setState({
			[e.target.name]: e.target.value,
		});
	}

	searchSubUserReport = async (e) => {
        e.preventDefault();
		if (new Date(this.state.startDate) > new Date(this.state.endDate)) {
			Notify.notify("Start date cannot be greater than end date.");
			return;
		}

		let op = '';

		if(this.state.searchpatchDuration!='' && this.state.pd!=''){
			op = this.state.searchpatchDuration+this.state.pd
		}else{
			op ='';
		}

		let sDate = "";
		let eDate =""
		if(this.state.startDate ===""){
             sDate = "";
		}else{
			 sDate = this.formatDate(this.state.startDate);
		}

		if(this.state.endDate ===""){
             eDate = "";
		}else{
			 eDate = this.formatDate(this.state.endDate);
		}
	
        const apiPath = this.props.app.apiPath;
		const userId = this.props.app.userId;
		const data = {
				userId : userId,
				startDate : sDate,
				endDate : eDate,
				campaignName : this.state.campaignName,
				campaignType : this.state.campaignType,
				mobile : this.state.mobile,
				patchDurationOperation : op
		}

		// 'http://localhost:8080'
		// apiPath
		Fetcher.post('http://localhost:8080' + '/api/obd/sub/user/report/list',{ headers: { "Content-type": "application/json" }, method: 'POST', body: JSON.stringify(data) })
			.then(res => {
				this.setState({
					reportList: res
				})
			})
			.catch(err => { console.log('Error in fetching sub user report', err) });
		
    }

	setMessageShow = ( status ) => {
		this.setState( {
			showModal: false,
		} );
	}
	downloadReport = (reportUrl) => {
				window.open(reportUrl, 'Download');
	}
	refreshPage = () => {
		this.setState({ loading: true });
		//window.location.reload();

		setTimeout(() => {
			window.location.reload(false);
		}, 500);
	}
	handleSizePerPageChange = (newSizePerPage) => {
		this.setState({ sizePerPage: newSizePerPage });
		Cookies.set('downloadReportListsizePerPage', newSizePerPage);
	}

    showListenModal = (promptUrl) => {
		
		this.setState( {
			showListenModal: true,
			modalHeading: 'Listen Recording',
			modalContent: <ReactPlayer url={`${promptUrl}`} playing />,
			selectedPromptUrl: promptUrl,
			copied: false
		} )
	}

	downloadVoiceFile = (promptUrl) => {
		window.open(promptUrl, 'Download');
	}

	setMessageShow = ( status ) => {
		this.setState( {
			showListenModal: false,
		} );
	}

	handleStartDateChange = (date) => {
		this.setState({
			startDate: date,
	    });
    };

    handleEndDateChange = (date) => {
    		this.setState({
    			endDate: date,
    	 });
    };

	render() {

		const reportList = this.state.reportList;
		const columns = [
			{
				dataField: 'campaignId',
				text: 'Campaign Id',
				sort: true,
				hidden: false
			},
			{
				dataField: 'campaignName',
				text: 'Campaign Name',
				sort: true,
				hidden: false
			},
			{
				dataField: 'bni',
				text: 'Mobile',
				sort: true,
				hidden: false
			},
			{
				dataField: 'cli',
				text: 'CLI',
				sort: true,
				hidden: false
			},
			{
				dataField: 'startTime',
				text: 'Start Time',
				sort: true,
				hidden: false
			}, {
				dataField: 'answerTime',
				text: 'Answer Time',
				sort: true,
				hidden: false
			},
			 {
				dataField: 'endTime',
				text: 'End Time',
				sort: true,
				hidden: false
			},
			{
				dataField: 'billingDuration',
				text: 'Billing Duration',
				sort: true,
				hidden: false
			},
			{
				dataField: 'answerStatus',
				text: 'Answer Status',
				sort: true,
				hidden: false
			},
			{
				dataField: 'hangupCause',
				text: 'Hangup Cause',
				sort: true,
				hidden: false
			},
			{
				dataField: 'agentNumber',
				text: 'Agent Number',
				sort: true,
				hidden: false
			},
			{
				dataField: 'bridgeTime',
				text: 'Bridge Time',
				sort: true,
				hidden: false
			},
			{
				dataField: 'bridgeRingDuration',
				text: 'Bridge Ring Duration',
				sort: true,
				hidden: false
			},
			{
				dataField: 'patchDuration',
				text: 'Patch Duration',
				sort: true,
				hidden: false
			},
			{
				dataField: 'bridgeHangupCause',
				text: 'Bridge Hangup Cause',
				sort: true,
				hidden: false
			},
			{
				dataField: 'dtmf',
				text: 'DTMF',
				sort: true,
				hidden: false
			},
			{
				dataField: 'recordingFile',
				text: 'Recording File',
				sort: true,
				hidden: false
			},

		];

		const { ExportCSVButton } = CSVExport;
	
		const report = Array.isArray(reportList) ? reportList.map( ( row, index ) => {
            
			let action = [];

		    action = [<a key={`prompt-listen-${index}`} className="btn-sm btn btn-default btn-primary ml-2 " onClick={(e) => { this.showListenModal(row.recordingFile) }} href="#">Listen</a>];
		    action.push(<a key={`prompt-download-${index}`} className=" btn-sm btn btn-default btn-success ml-2 mt-2 " onClick={(e) => { this.downloadVoiceFile(row.recordingFile) }} href="#">Download</a>);

			return {
				campaignId: row.campaignId,
				campaignName: row.campaignName,
				bni: row.bni,
				cli: row.cli,
				startTime: row.startTime,
                answerTime : row.answerTime,
				endTime : row.endTime,
				billingDuration : row.billingDuration,
				answerStatus : row.answerStatus,
				hangupCause : row.hangupCause,
				agentNumber : row.agentNumber,
				bridgeTime : row.bridgeTime,
				bridgeRingDuration : row.bridgeRingDuration,
				patchDuration : row.patchDuration,
				bridgeHangupCause : row.bridgeHangupCause,
				dtmf : row.dtmf,
				recordingFile : action
				};
		}): []

		const { SearchBar, ClearSearchButton } = Search;

		const defaultSorted = [ {
			dataField: 'campaignId',
			order: 'desc'
		} ];

		const paginationOption = {
			custom: true,
			totalSize: report.length,
			sizePerPage: this.state.sizePerPage,
			sizePerPageList: [ 10, 25, 50, 100 ],
			onSizePerPageChange: this.handleSizePerPageChange
		};

		const uniqueCampaignNames = Array.isArray(reportList) 
			? [...new Set(reportList.map(campaign => campaign.campaignName))] 
			: [];
       
		const campaignNameDropdown = uniqueCampaignNames.map(name => (
			<option key={name} value={name}>{name}</option>
		));

		// const uniqueCampaignMobile = Array.isArray(reportList) 
		// 	? [...new Set(reportList.map(campaign => campaign.bni))] 
		// 	: [];
       
		// const campaignMobileDropdown = uniqueCampaignMobile.map(name => (
		// 	<option key={name} value={name}>{name}</option>
		// ));

		return (
			<Card>
					<Card.Header>Report</Card.Header>
					<Card.Body>
						<div className="widget-list-table-cont">

                        <Form className="add-campaign-form" method='post' onSubmit={(e) => this.searchSubUserReport(e)}> 
					        <Row>
						        <Col sm={2}>
                                    <Form.Group className="form-group col-md-12 float-left" style={{ paddingLeft: 0 }}>
					                    <DatePicker
						                    className="form-control"
						                    dateFormat="yyyy-MM-dd"
                                            selected={this.state.startDate}
                                            onChange={this.handleStartDateChange}
                                            placeholderText="Start Date"
					                    />
				                    </Form.Group>
                                </Col>
                                <Col sm={2}>
                                    <Form.Group className="form-group col-md-12 float-left" style={{ paddingLeft: 0 }}>
					                    <DatePicker
						                    className="form-control"
						                    dateFormat="yyyy-MM-dd"
						                    selected={this.state.endDate}
                                            onChange={this.handleEndDateChange}
                                            placeholderText="End Date"
					                    />
				                    </Form.Group>
                                </Col>
                                  
								<Col sm={3}>
										<Form.Group className="form-group required control-label">
											<Form.Select className="dd" name="campaignType" onChange={e => this.setvalue(e)} as="select">
												<option value="">Select Campaign Type</option>
												<option value="1">DTMF</option>
                                                <option value="2">Call Patch</option>
											</Form.Select>
										</Form.Group>
								</Col>

								<Col sm={4}>		
									<Form.Group className="form-group"  controlId="sender-id">
										{/* <Form.Label>Campaign Type</Form.Label> */}
										<Form.Select as="select" name="campaignName" onChange={e => this.setvalue(e)}>
										    <option value="">Select Campaign Name</option>
											<option value="">All</option>
											{campaignNameDropdown}
										</Form.Select>
									</Form.Group>
								</Col>

						    </Row>	


							<Row>

                                <Col sm={3}>
										<Form.Group className="form-group required control-label">
											<Form.Control name="mobile" type="number" placeholder="Mobile" onChange={e => this.setvalue(e)} value={this.state.mobile} autoComplete="off" />
										</Form.Group>
								</Col>

								<Col sm={3}>
										<Form.Group className="form-group required control-label">
											<Form.Select className="dd" name="searchpatchDuration" onChange={e => this.setvalue(e)} as="select">
												<option value="">Select Patch Duration</option>
												<option value=">">Greater Than</option>
                                                <option value="<">Less Than</option>
												<option value="=">Equal To</option>
											</Form.Select>
										</Form.Group>
								</Col>
                               { (this.state.searchpatchDuration !='') &&
								 <Col sm={3}>
										<Form.Group className="form-group required control-label">
											<Form.Control required name="pd" type="number" placeholder="Duration" onChange={e => this.setvalue(e)} value={this.state.pd} autoComplete="off" />
										</Form.Group>
								</Col>
	                            }
                                   
								<Col sm={3}>
                                    <Button variant="primary" type="submit">Search</Button>
                                </Col>
							</Row>
					    </Form> 


						<PaginationProvider pagination={paginationFactory(paginationOption)}>
								{({
									paginationProps, paginationTableProps
								}) => (
									<ToolkitProvider
										keyField="campaignId"
										data={report}
										columns={columns}
										search
										bootstrap4
										exportCSV={ {
											fileName: 'sub_user_report.csv'
										  } }

									>
										{
											props => (
												<div className="">
													Show <SizePerPageDropdownStandalone className="search-dropdown" {...paginationProps} /> items
													<span className="search-cont"><SearchBar {...props.searchProps} /></span>
													<ClearSearchButton className="btn-primary btn-round" {...props.searchProps} />
													<Button id="refresh" type="button" className="float-right btn btn-default btn-primary ml-2" onClick={() => this.refreshPage()}>Refresh</Button>
													<ExportCSVButton className="export-csv btn-primary ml-2" {...props.csvProps}>Export to CSV</ExportCSVButton>
													<hr/>
													<BootstrapTable defaultSorted={defaultSorted} bordered={true} striped={true} condensed wrapperClasses="table-responsive" classes="widgetlist-table" {...props.baseProps} {...paginationTableProps} noDataIndication={() => (<div>{'No data available'}</div>)} />
													<PaginationListStandalone {...paginationProps} />
												</div>
											)}
									</ToolkitProvider>
								)}
							</PaginationProvider>
						</div>
						

						<Modal size="lg" show={this.state.showListenModal} onHide={() => this.setMessageShow( false )}>
						    <Modal.Header closeButton>
						     	<Modal.Title className="modal-heading" id="example-modal-sizes-title-sm">
								    {this.state.modalHeading}
							    </Modal.Title>
						    </Modal.Header>
						<Modal.Body > 
							<ReactPlayer url={`${this.state.selectedPromptUrl}`} controls width='90%' height='80px' />
							
						</Modal.Body>

				     	</Modal>


					</Card.Body>
				</Card>


		);
	}
}

export default SubUserReport;